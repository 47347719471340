import React, { useEffect, useState } from "react";
import "./form.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";

// import Registration from ""
const FormRegistration = () => {
  var payment_id = "";

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    address: "",
    mobile: "",
    email: "",
    qualification: "",
    pinCode: "",
    password: "",
    confirmPassword: "",
    // uploadResume: "",
    examCenter: "",
  });
  const [exam, setExam] = useState([]);

  useEffect(() => {
    handalExam();
  }, []);

  // handle exam list
  const handalExam = async () => {
    const response = await axios.get(API_BASE_URL + "user/cities", {
      auth: {
        username: API_AUTH_USERNAME,
        password: API_AUTH_PASSWORD,
      },
    });
    setExam(response.data.data);
  };

  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (event) => {
    const { name, value, file } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      file,
    }));
    setErrors({ ...errors, [name]: "" });
  };

  // validatedForm

  const validateForm = (data) => {
    let errors = {};
    let valid = true;
    const regex = /^[A-Za-z\s]+$/; // Regular expression to allow only letters and spaces
    if (!data.fullName.trim()) {
      errors.fullName = "FullName name is required";
      valid = false;
    } else if (!regex.test(data.fullName)) {
      errors.fullName = "Full Name can only contain letters and spaces";
      valid = false;
    }

    if (!data.address.trim()) {
      errors.address = "Address is required";
      valid = false;
    }
    if (!data.mobile.trim() || data.mobile.length !== 10) {
      errors.mobile = "Mobile Number is required";
      valid = false;
    }

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Invalid email format";
      valid = false;
    }

    if (!data.qualification.trim()) {
      errors.qualification = "Qualification is required";
      valid = false;
    }

    if (!data.pinCode.trim() || data.pinCode.length !== 6) {
      errors.pinCode = "Pin Code is required ";
      valid = false;
    }

    if (!data.password.trim()) {
      errors.password = "Password is required";
      valid = false;
    } else if (data.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
      valid = false;
    }

    if (!data.confirmPassword.trim()) {
      errors.confirmPassword = "Confirm password is required";
      valid = false;
    } else if (data.password !== data.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
      valid = false;
    }

    if (!data.examCenter.trim()) {
      errors.examCenter = "Please select exam center";
      valid = false;
    }

    // if (!data.uploadResume.trim()) {
    //   errors.uploadResume = "Please select a pdf file.";
    //   valid = false;
    // }

    return errors;
  };

  // handle Validate

  const handleValidate = async (event) => {
    const { mobile, email } = formData;

    event.preventDefault();

    // Perform form validation before submitting to the server
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, submit the data to the server or perform further actions

      try {
        const finalData = {
          email: email,
          mobile: mobile,
        };

        const response = await axios.post(
          API_BASE_URL + "user/validate",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        if (response.data.status) {
          handlePayment(event);
        } else {
          window.alert(response.data.error);
          setResponseMessage(response.data.message || "Registration failed.");
        }
      } catch (error) {
        console.log(error.response.data.error);
        // setErrors('An error occurred. Please try again later.');
        setResponseMessage("An error occurred. Please try again later.");
      }
    } else {
      setErrors(validationErrors);
    }
  };

  // payment handle

  const handlePayment = async (event) => {
    const {
      fullName,
      mobile,
      email
    } = formData;

    event.preventDefault();

    // Perform form validation before submitting to the server
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      function loadScript(src) {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => {
            resolve(false);
          };
          document.body.appendChild(script);
        });
      }

      //start
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const options = {
        key: "rzp_live_BDRl6BUgC0RF7G", // Enter the Key ID generated from the Dashboard
        currency: "INR",
        amount: "32000",
        name: "SVK SUPPLIERS PRIVATE LIMITED",
        description: "Thanks For Purchasing",
        image: "../../component/asset/image/logo.png",

        handler: function (response) {
          payment_id = response.razorpay_payment_id;
          handleSubmit(event);
        },
        prefill: {
          name: fullName,
          email: email,
          contact: mobile,
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      setErrors(validationErrors);
    }
  };

  // handle form on submit

  const handleSubmit = async (event) => {
    const {
      fullName,
      address,
      mobile,
      email,
      qualification,
      pinCode,
      password,
      // uploadResume,
      confirmPassword,
      examCenter,
    } = formData;

    event.preventDefault();

    // Perform form validation before submitting to the server
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const finalData = {
          name: fullName,
          address: address,
          email: email,
          // file: uploadResume,
          password: password,
          mobile: mobile,
          confirmPassword: confirmPassword,
          qualification: qualification,
          pincode: pinCode,
          nearest_city: examCenter,
          amount: "300",
          payment_id: payment_id,
        };

        const response = await axios.post(
          API_BASE_URL + "user/register",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );

        if (response.data.status) {
          // Registration successful, display success message
          toast.success(response.data.message);
          // window.alert(response.data.message);
          navigate("/success");
        } else {
          // Registration failed, display error message from the API
          window.alert(response.data.error);
          setResponseMessage(response.data.message || "Registration failed.");
        }
      } catch (error) {
        console.log(error.response.data.error);
        // setErrors('An error occurred. Please try again later.');
        setResponseMessage("An error occurred. Please try again later.");
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div>
      <Helmet>
        <meta
          name="keywords"
          content="staff management, employee registration, staff registration, employee onboarding, HR registration, human resources form, employee information form"
        />
        <meta
          name="description"
          content="Register your employees seamlessly with our user-friendly staff management website Registration Form. Streamline the employee onboarding process and gather essential information efficiently. Sign up now and simplify your HR management."
        />
        <title>Registration Form | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <section className="container mt-5 py-5 mb-5">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center align-items-center ">
            <div className="form_letter mt-5 mb-5">
              <div className="forminput">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-title">
                      <h1 className="fs-5 common-text fw-bold mb-5 text-center">
                        Registration Form
                      </h1>
                      {/* <div className="google-translation float-end mb-4">
                      <div className="g-tran" id="google_translate_element"></div>
                    </div> */}
                    </div>
                  </div>
                  {/* form start */}
                  <form onSubmit={handleValidate}>
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6  mb-4">
                          <div className="form-group">
                            <input
                              type="text"
                              name="fullName"
                              value={formData.fullName}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="परीक्षार्थीचे पूर्ण नाव"
                            />
                            {errors.fullName && (
                              <span className="text-danger">
                                {errors.fullName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6  mb-4">
                          <div className="form-group">
                            {/* <label className='label_form' htmlFor="">परीक्षार्थीचा पत्ता  :</label> */}
                            <input
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="परीक्षार्थीचा पत्ता "
                            />
                            {errors.address && (
                              <span className="text-danger">
                                {errors.address}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                          <div className="form-group">
                            <input
                              type="number"
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleChange}
                              className="form-control"
                              placeholder=" परीक्षार्थीचा मोबाईल नंबर"
                            />
                            {errors.mobile && (
                              <span className="text-danger">
                                {errors.mobile}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6  mb-4">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="परीक्षार्थीचा ईमेल आयडी "
                            />
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6  mb-4">
                          <div className="form-group">
                            <input
                              type="text"
                              name="qualification"
                              value={formData.qualification}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="परीक्षार्थीचे  शैक्षणिक पात्रता  "
                            />
                            {errors.qualification && (
                              <span className="text-danger">
                                {errors.qualification}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6  mb-4">
                          <div className="form-group">
                            <input
                              type="number"
                              name="pinCode"
                              value={formData.pinCode}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="पिन कोड"
                            />
                            {errors.pinCode && (
                              <span className="text-danger">
                                {errors.pinCode}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6  mb-4">
                          <div className="form-group">
                            <input
                              type="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="पासवर्ड टाका"
                            />
                            {errors.password && (
                              <span className="text-danger">
                                {errors.password}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6  mb-4">
                          <div className="form-group">
                            <input
                              type="password"
                              name="confirmPassword"
                              value={formData.confirmPassword}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="पासवर्डची पुष्टी करा"
                            />
                            {errors.confirmPassword && (
                              <span className="text-danger">
                                {errors.confirmPassword}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6  mb-4">
                          <div className="form-group">
                            <label
                              className="label_form fw-bold mb-2"
                              htmlFor=""
                              style={{ fontSize: "15px" }}
                            >
                              परीक्षा केंद्र
                            </label>
                            <select
                              name="examCenter"
                              value={formData.examCenter}
                              className="form-control"
                              onChange={handleChange}
                            >
                              <option value="">परीक्षा केंद्र निवडा </option>
                              {exam.map((item) => {
                                return (
                                  <option value={item.name} key={item.id}>
                                    {item.city_name}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.examCenter && (
                              <span className="text-danger">
                                {errors.examCenter}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-lg-6  mb-4">
                        <div className="form-group">
                          <label
                            className="label_form fw-bold mb-2"
                            htmlFor=""
                            style={{ fontSize: "15px" }}
                          >
                            अपलोड रेज्युम
                          </label>
                          <input
                            type="file"
                            title="upload resume"
                            className="form-control"
                            name="uploadResume"
                            accept=".pdf"
                            value={formData.uploadResume}
                            onChange={handleChange}
                          />
                          {errors.uploadResume && (
                            <span className="text-danger">
                              {errors.uploadResume}
                            </span>
                          )}
                        </div>
                      </div> */}

                        <div className="col-lg-12">
                          <div className="rule mt-3">
                            <h6 className="fw-bold">नियम व अटी - </h6>
                            <p>
                              1) वरील सर्व पदे हि प्रश्नोतरे (MCQ) पद्धतीने भरली
                              जातील.
                            </p>
                            <p>
                              २) परिक्षार्थीने आपला चालू मोबाईल नंबर द्यावे व
                              आपली योग्य ती माहिती द्यावी. अन्यथा संस्था जबाबदार
                              राहणार नाही.
                            </p>
                            <p className="text-danger fw-bold">
                              ३) अर्ज फक्त सोलापूर जिल्यातील मुला - मुलीसाठी आहे
                              .इतर कोणत्याही जिल्यातील अर्ज स्वीकारले जाणार
                              नाहीत .
                            </p>
                            <p>
                              ४) आपली पोच व्यवस्थित ठेवून परीक्षेवेळी घेऊन यावी
                              हेच आपले प्रवेशपत्र समजले जाईल .{" "}
                            </p>
                            <p>
                              ५) परीक्षेच्या वेळापत्रकात बदल करून व इतर अधिकार
                              संस्थेकडे राहतील .
                            </p>
                            <p>
                              ६) गावाचे नाव योग्य प्रकारे लिहावे जेणेकरून
                              आपल्याला जवळील परीक्षा केंद्र उपलब्ध करून देण्यात
                              आपल्याला मदत होईल .
                            </p>
                            <p>७) या सर्व नोकर्‍या करार स्वरुपाच्या राहतील .</p>
                            <p>८) जे विद्यार्थी कंपनी ची स्किल टेस्ट / परीक्षा पास होतील आणि ज्यांना नोकरी करायची नाही त्यांनाच कंपनी कडून फक्त 30 हजार रुपये मिळतील</p>
                            <p>९) परीक्षा फी 320 रुपये राहील . </p>
                          </div>
                        </div>
                        <div className="col-lg-6  mb-4">
                          <div className="form-group">
                            {/* <button className="btn btn-primary">Register</button> */}
                            <Button
                              variant="contained"
                              className="fw-bold"
                              type="submit"
                              color="primary"
                              endIcon={<ArrowCircleRightIcon />}
                              style={{ lineHeight: "10px" }}
                            >
                              Register
                            </Button>
                            {responseMessage && (
                              <div class="alert alert-danger mt-3 alert-dismissible fade show">
                                <strong>Alert! </strong>
                                {responseMessage}
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="alert"
                                ></button>
                              </div>
                            )}
                            <ToastContainer />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormRegistration;
