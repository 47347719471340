import React from "react";
import { Helmet } from "react-helmet";
import careerBg from "../../component/asset/image/carriers.png";
import "../Privacy_Policy/Privacy.css";
const ShippingDelivery = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Looking to join our dynamic team? Explore exciting career opportunities in staff management and apply your skills in a supportive and growth-oriented environment. Join us today!"
        />
        <meta
          name="description"
          content="Staff management jobs, career opportunities, join our team, staff recruitment, professional growth"
        />
        <title>Shipping Delivery | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <div className="current-opening">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={careerBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <header>
          <h2 className="text-uppercase common-text fw-bold text-center mt-5">
            SHIPPING DELIVERY
          </h2>
        </header>
        <div className="row">
          <div className="col-lg-12">
            <div className="staffing_privacy pt-3 mt-5">
              <div className="">
                <h5 className="svk_privacy_heading">
                  SVK SUPPLIERS PRIVATE LIMITED SHIPPING DELIVERY (updated
                  December 29, 2023)
                </h5>
                <p className="staffing_para">
                  This shipping delivery describes how your personal information
                  is collected, used, and shared when you visit or make a
                  purchase from https://svkconsultant.com/ (the “Site”) and any
                  all subsidiary sites including
                  https://svkconsulting.com/ Roleplay
                  Workshop, A Day in The Life of a Staffingpreneur, Million
                  Dollar Playbook, 7-Figure Sales Masterclass, 6-Figure Resume
                  Writing Biz, Rookie Recruiter Training, How To Get Staffing
                  Clients, A Crash Course in Starting a Recruitment & Staffing
                  Business,  svkconsultingbusiness.com.
                </p>
              </div>
              <div className="">
                <h5 className="svk_privacy_heading">
                  Shipping Cost Information
                </h5>
                <p className="staffing_para">
                  Shipping costs are not available at the moment. Please contact
                  our customer support for assistance.
                </p>
              </div>
              <div className="">
                <h5 className="svk_privacy_heading">What Is Shipping?</h5>
                <p className="staffing_para">
                  There are two main definitions of shipping when it comes to
                  logistics. The first definition speaks to the size of the
                  package. Smaller objects, such as shoes, clothes, and
                  accessories, can fall under the category of shipping since
                  they can be boxed and sent to the customer using a postal
                  service. The second definition refers to when items are
                  required to be sent to customers. As such, consumers can
                  understand the shipping date to be the day on which the item
                  was dispatched and begin making its way to them.
                </p>
              </div>
              {/* personal information */}
              <div className="my-5">
                <h5 className="svk_privacy_heading">What Is Delivery?</h5>
                <p className="staffing_para">
                  Delivery often refers to larger objects, like major appliances
                  and furniture, which require installation or a delivery person
                  to fetch it inside your home—items that need to be delivered
                  are usually too big to be shipped. Delivery is also the term
                  used to refer to the date on which the package will arrive at
                  the customer’s doorstep. However, this date is usually a
                  general estimate since it’s uncontrollable by the vendor and
                  can be delayed by unforeseen circumstances.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  Shipping vs. Delivery: What’s the Difference?
                </h5>
                <p className="staffing_para">
                  Overall, there are two main differences between shipping and
                  delivery. The first is the size: Smaller items are shipped
                  while larger items are delivered. The second difference is the
                  date on which each takes place. Shipping dates usually refer
                  to when an item leaves the warehouse while the delivery date
                  specifies when it should reach the customer. If you’re looking
                  for a fulfillment company that’ll make your delivery date
                  almost the same as the shipping date, then choose APS
                  Fulfillment Inc. We serve a variety of industries, so whether
                  you’re looking to ship retail, healthcare or financial items,
                  or commercial furniture, we’ve got the solution for you. One
                  of the main benefits of allowing APS to handle your
                  fulfillment needs is the flexibility it allows your business.
                  You’ll be able to not only save money and time by eliminating
                  the need to set up your own fulfillment system, but you’ll
                  also be able to scale your fulfillment services depending on
                  the growth of your business. Fast and dependable fulfillment
                  services are integral to maintaining a good rapport with
                  customers, which is why you should trust APS—we take extra
                  care to ensure your customers receive their packages on time,
                  and in good condition. Contact us today to learn more about
                  how we can help your small business continue to grow.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  Fair Information Practices
                </h5>
                <p className="staffing_para">
                  The Fair Information Practices Principles form the backbone of
                  privacy law in the United States and the concepts they include
                  have played a significant role in the development of data
                  protection laws around the globe. Understanding the Fair
                  Information Practice Principles and how they should be
                  implemented is critical to comply with the various privacy
                  laws that protect personal information. In order to be in line
                  with Fair Information Practices we will take the following
                  responsive action, should a data breach occur:
                </p>
                <p className="staffing_para">We will notify you via email</p>
                <ul>
                  <li className="staffing_para">Within 7 business days</li>
                </ul>
                <p className="staffing_para">
                  We will notify the users via in-site notification
                </p>
                <ul>
                  <li className="staffing_para">Within 7 business days</li>
                </ul>
                <p className="staffing_para">
                  We also agree to the Individual Redress Principle which
                  requires that individuals have the right to legally pursue
                  enforceable rights against data collectors and processors who
                  fail to adhere to the law. This principle requires not only
                  that individuals have enforceable rights against data users,
                  but also that individuals have recourse to courts or
                  government agencies to investigate and/or prosecute
                  non-compliance by data processors.
                </p>
                <p className="staffing_para">
                  The CAN-SPAM Act is a law that sets the rules for commercial
                  email, establishes requirements for commercial messages, gives
                  recipients the right to have emails stopped from being sent to
                  them, and spells out tough penalties for violations. We
                  collect your email address in order to:
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Contact Us</h5>
                <p className="staffing_para">
                  For more information about our privacy practices, if you have
                  questions, or if you would like to make a complaint, please
                  contact our corporate office. You can reach us by e-mail at
                  inquiry@svkconsultant.com or by mail using the details
                  provided below. Additionally, feel free to contact us directly
                  at +91 8686553001 for any assistance or inquiries
                </p>
                <p className="staffing_para">
                  Pentagon Building, 401, Pune - Satara Rd, near hotel
                  panchami, Pune, Maharashtra 411009
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingDelivery;
