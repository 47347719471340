import "./App.css";
import Login from "./component/login/Login";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Footer from "./component/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact/Contact";
import Recruitment from "./pages/Services/SubPages/Recruitment";
import Training from "./pages/Services/SubPages/Training";
import ContentDevelopment from "./pages/Services/SubPages/Job_Fair";
import ItServices from "./pages/Services/SubPages/Staff_Services";
import Labour_healthcare from "./pages/Services/SubPages/Labour_healthcare";
import Header from "./component/Header/Header";
import Why_Right from "./pages/Career/Why_Right";
import Current_Opening from "./pages/Career/Current_Opening";
import FormRegistration from "./pages/Form/FormRegistration";
import ShowNavbar from "./component/ShowNavbar/ShowNavbar";
import UserPanel from "./pages/UserPanel/UserPanel";
import RazorpayMain from "./pages/razorpay/RazorpayMain";
import SuccessBox from "./component/SuccessBox/SuccessBox";
import NewForm from "./pages/Form/NewForm";
import Privacy_Policy from "./pages/Privacy_Policy/Privacy_Policy";
import React from "react";

import CancellationRefund from "./pages/cancellationRefund/CancellationRefund";
import TermsCondition from "./pages/termsCondition/TermsCondition";
import ShippingDelivery from "./pages/shippingDelivery/ShippingDelivery";
function App() {
  return (
    <div>
      {/* <Navbar/> */}
      <ShowNavbar>
        <Header />
      </ShowNavbar>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        {/* services */}
        <Route path="/recruitment" element={<Recruitment />} />
        <Route path="/training" element={<Training />} />
        <Route path="/job_fair" element={<ContentDevelopment />} />
        <Route path="/staff_services" element={<ItServices />} />
        <Route path="/labour_healthcare" element={<Labour_healthcare />} />
        {/* career  */}
        <Route path="/why" element={<Why_Right />}></Route>
        <Route path="/current_opening" element={<Current_Opening />}></Route>
        {/* contact */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/registration_form" element={<FormRegistration />} />
        <Route path="/form" element={<NewForm />} />
        {/* user panel */}
        <Route path="/user-panel" element={<UserPanel />} />
        <Route path="/razorpay" element={<RazorpayMain />} />
        {/* success box */}
        <Route path="/success" element={<SuccessBox />} />

        {/* privacy policy */}
        <Route path="/privacy_policy" element={<Privacy_Policy />} />
        <Route path="/cancellation-refund" element={<CancellationRefund />} />
        <Route path="/term_condition" element={<TermsCondition />} />
        <Route path="/shipping_Delivery" element={<ShippingDelivery />} />
      </Routes>
      <ShowNavbar>
        <Footer />
      </ShowNavbar>
    </div>
  );
}

export default App;
