import React, { useEffect } from "react";
import "./Success.css";
import successBg from "../../component/asset/image/successImg.png";
import successBgIcon from "../../component/asset/image/reg-success.png";
import { useNavigate } from "react-router-dom";
import UserHeader from "../../component/Header/UserPanelHeader/UserHeader";
import UserFooter from "../../component/Footer/UserPanelFooter/UserFooter";

const SuccessBox = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const refreshPage = () => {
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    };
    refreshPage();
  });
  return (
    <>
      <UserHeader />
      <section className="py-5 mt-5" style={{ background: "cadetblue" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className="success-bg py-5">
                {/* <img
                  src={successBg}
                  alt="successBg"
                  className="img-fluid rounded"
                  width={600}
                /> */}
                <div className="card success-card">
                  <div className="card-body success-card-body text-center">
                    <img
                      src={successBgIcon}
                      alt="success-icon"
                      className="img-fluid mb-4"
                      width={100}
                    />
                    <h2 className="text-success fw-bold mb-4">
                      Registration Successful !
                    </h2>
                    <p className="text-uppercase fw-bold">
                      Your Registration has been Successful{" "}
                    </p>
                    {/* <button className="btn btn-primary">Ok</button> */}
                    {/* <p>Your Hall Ticket Will Be Generated Please Download Now</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UserFooter />
    </>
  );
};

export default SuccessBox;
