import React from "react";
import { Helmet } from "react-helmet";
import careerBg from "../../component/asset/image/carriers.png";
import "../Privacy_Policy/Privacy.css";
const CancellationRefund = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Looking to join our dynamic team? Explore exciting career opportunities in staff management and apply your skills in a supportive and growth-oriented environment. Join us today!"
        />
        <meta
          name="description"
          content="Staff management jobs, career opportunities, join our team, staff recruitment, professional growth"
        />
        <title>
          REFUND AND CANCELLATION POLICY | SVK SUPPLIERS PRIVATE LIMITED
        </title>
      </Helmet>
      <div className="current-opening">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={careerBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <header>
          <h2 className="text-uppercase common-text fw-bold text-center mt-5">
            REFUND AND CANCELLATION POLICY
          </h2>
        </header>
        <div className="row">
          <div className="col-lg-12">
            <div className="staffing_privacy pt-3 mt-5">
              <div className="">
                <h5 className="svk_privacy_heading">
                  SVK SUPPLIERS PRIVATE LIMITED REFUND AND CANCELLATION POLICY
                  (updated December 29, 2023)
                </h5>
                <p className="staffing_para">
                  This policy describes how your personal information is
                  collected, used, and shared when you visit or make a purchase
                  from https://svkconsultant.com/ (the “Site”) and any all
                  subsidiary sites including 
                  https://svkconsulting.com/ Roleplay
                  Workshop, A Day in The Life of a Staffingpreneur, Million
                  Dollar Playbook, 7-Figure Sales Masterclass, 6-Figure Resume
                  Writing Biz, Rookie Recruiter Training, How To Get Staffing
                  Clients, A Crash Course in Starting a Recruitment & Staffing
                  Business,  svkconsulting.com.
                </p>
              </div>
              <div className="">
                <p className="staffing_para">
                  Our focus is complete customer satisfaction. In the event, if
                  you are displeased with the services provided, we will refund
                  back the money, provided the reasons are genuine and proved
                  after investigation. Please read the description of our
                  services thoroughly before making any type of payment.
                </p>
                <p className="staffing_para">
                  Our Policy for the cancellation and refund will be as follows:
                </p>
              </div>
              <div className="">
                <h5 className="svk_privacy_heading">Returns and Exchanges</h5>
                <p className="staffing_para">
                  We regret to inform you that returns and exchanges are not
                  available at this time.
                </p>
              </div>
              {/* personal information */}
              <div className="my-5">
                <h5 className="svk_privacy_heading">REFUND POLICY</h5>
                <p className="staffing_para">
                  We will try our best to create the suitable skill-upgradation
                  training module for our clients.
                </p>
                <p className="staffing_para">
                  We do not offer any refund whatsoever if you cancel or
                  discontinue the skill-upgradation training in the middle.
                </p>
                <p className="staffing_para">
                  In rare cases, if we decide to make a refund, refunds will be
                  issued to the original credit card provided at the time of
                  purchase if paid by credit card, and in case of payment
                  gateway name payments refund will be made to the same account.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">PRICING</h5>
                <p className="staffing_para">
                  we are not provide any e-commerce services
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Contact Us</h5>
                <p className="staffing_para">
                  For more information about our privacy practices, if you have
                  questions, or if you would like to make a complaint, please
                  contact our corporate office. You can reach us by e-mail at
                  inquiry@svkconsultant.com or by mail using the details
                  provided below. Additionally, feel free to contact us directly
                  at +91 8686553001 for any assistance or inquiries
                </p>
                <p className="staffing_para">
                  Pentagon Building, 401, Pune - Satara Rd, near hotel
                  panchami, Pune, Maharashtra 411009
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancellationRefund;
