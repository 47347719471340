import React from "react";
import ContentDevelopmentLastSec from "../../../component/asset/image/job-fair-right-bg.jpg";
import { Helmet } from "react-helmet";
import jobBg from "../../../component/asset/image/services/s3.webp";
const ContentDevelopment = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="staff management, job fair, career opportunities, hiring event, job openings, recruitment, employment, job seekers, workforce, job fair registration"
        />
        <meta
          name="description"
          content="Discover exciting career opportunities at our Staff Management Job Fair. Join us for a day of networking and exploration as top companies present their job openings. Register now to connect with employers and take your career to new heights."
        />
        <title>Job Fair | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <section className="content-development">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active content-development-bg">
              <img src={jobBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
        <div className="content-development-second-sec mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="content-development-top-sec">
                  <h3 className="text-uppercase common-text">Job Fair</h3>
                  <hr className="heading_line" />
                  <div className="content-development-common-sec">
                    <p className="content-development-left-para mb-4">
                      A job fair of a company, also known as a career fair or
                      recruitment fair, is an event organized by the company to
                      bring together job seekers and potential employers in one
                      location. The primary purpose of a job fair is to
                      facilitate the recruitment process and connect companies
                      with qualified candidates who are seeking employment
                      opportunities.
                    </p>
                    {/* <span className="training-span mt-2">
                    Setting up Booths/Stations
                  </span>
                  <p className="content-development-left-para mb-4">
                    On the day of the job fair, the company sets up booths or
                    stations at the venue. Each booth represents a specific
                    department or job position within the company.
                  </p> */}
                    {/* <h3 className="text-uppercase">Why Join Us?</h3>
                  <hr className="heading_line" />
                  <p className="para-table">Opportunity for Growth</p>
                  <p className="para-table"> Innovative Environment</p>
                  <p className="para-table">Work-Life Balance</p>
                  <p className="para-table">Diverse and Inclusive Culture</p>
                  <p className="para-table">Collaborative Team</p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-dev-left-sec">
                  <span className="training-span common-text mt-2">
                    Here's how a job fair of a company typically works:
                  </span>
                  <br />
                  <span className="training-span mt-2">Event Organization</span>
                  <p className="content-development-left-para mb-4">
                    The company arranges the job fair, either independently or
                    in collaboration with other organizations or educational
                    institutions. The event is usually held at a physical venue,
                    such as a convention center, hotel, or college campus. In
                    some cases, virtual job fairs are also conducted online.
                  </p>
                  <span className="training-span mt-2">
                    Inviting Participants
                  </span>
                  <p className="content-development-left-para mb-4">
                    The company arranges the job fair, either independently or
                    in collaboration with other organizations or educational
                    institutions. The event is usually held at a physical venue,
                    such as a convention center, hotel, or college campus. In
                    some cases, virtual job fairs are also conducted online.
                  </p>
                  <span className="training-span mt-2">Preparation</span>
                  <p className="content-development-left-para mb-4">
                    Before the job fair, the company's HR team or recruiters
                    prepare to represent the organization at the event. They may
                    create promotional materials, job descriptions, and
                    informational handouts about the company and available job
                    positions.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center">
                <div className="content-development-right-sec">
                  <img
                    src={ContentDevelopmentLastSec}
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentDevelopment;
