import React from "react";
import "../Services.css";
import requirementImg from "../../../component/asset/image/services/recruitment-right-bg.png";
import recruitmentBg from "../../../component/asset/image/services/s1.webp";
import { Helmet } from "react-helmet";
const Recruitment = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Job openings,Staff recruitment,Employment opportunities,Hiring process,Careers,Work at  SVK SUPPLIERS PRIVATE LIMITED,Vacancies,Join our team,Job applications,Staff positions"
        />
        <meta
          name="description"
          content="Explore exciting job opportunities at company. Join our team of talented professionals and embark on a rewarding career journey. We offer a diverse range of job openings in various departments. Apply now and be a part of our thriving workforce."
        />
        <title>Recruitment | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <section className="recruitment">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active recruitment-bg">
              <img src={recruitmentBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
        <div className="recruitment-second-sec mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="recruitment-left-sec">
                  <h3 className="text-uppercase common-text">recruitment</h3>
                  <hr className="heading_line" />
                  <div className="recruitment-common-sec">
                    <p className="recruitment-left-para">
                      Recruitment is the process of identifying, attracting, and
                      selecting qualified individuals to fill job vacancies
                      within an organization. It is a critical function of human
                      resource management and plays a crucial role in building a
                      talented and capable workforce for the company.
                    </p>
                    <span className="recruitment-span common-text mt-2">
                      The recruitment process typically involves the following
                      steps:
                    </span>
                    <p className="recruitment-left-para">
                      Job Analysis and Job Description: Before starting the
                      recruitment process, the hiring manager or HR team
                      conducts a job analysis to understand the role's
                      requirements, responsibilities, and qualifications. Based
                      on the analysis, a detailed job description is created,
                      outlining the job title, duties, skills, experience, and
                      other relevant information.
                    </p>
                    <p className="recruitment-left-para">
                      Job Analysis and Job Description: Before starting the
                      recruitment process, the hiring manager or HR team
                      conducts a job analysis to understand the role's
                      requirements, responsibilities, and qualifications. Based
                      on the analysis, a detailed job description is created,
                      outlining the job title, duties, skills, experience, and
                      other relevant information.
                    </p>
                    <p className="recruitment-left-para">
                      Internal sources: Considering existing employees for
                      promotions or transfers. External sources: Advertising job
                      openings on the company website, job boards, social media,
                      career fairs, and professional networks. Recruitment
                      agencies: Collaborating with external agencies to identify
                      suitable candidates.
                    </p>
                    {/* <p className="recruitment-left-par">
                    Screening Applications: The received applications and
                    resumes are reviewed to shortlist candidates who meet the
                    initial qualifications outlined in the job description.
                  </p>
                  <p className="recruitment-left-par">
                    Conducting Interviews: Shortlisted candidates are invited
                    for interviews, which may involve one or multiple rounds.
                    Interviews help assess the candidates' skills, experience,
                    cultural fit, and suitability for the role.
                  </p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 d-flex justify-content-center align-items-center">
                <div className="recruitment-right-sec">
                  <img src={requirementImg} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Recruitment;
