import React from "react";
import { Helmet } from "react-helmet";
import careerBg from "../../component/asset/image/carriers.png";
import "../Privacy_Policy/Privacy.css";
const TermsCondition = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Looking to join our dynamic team? Explore exciting career opportunities in staff management and apply your skills in a supportive and growth-oriented environment. Join us today!"
        />
        <meta
          name="description"
          content="Staff management jobs, career opportunities, join our team, staff recruitment, professional growth"
        />
        <title>Terms & Conditions | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <div className="current-opening">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={careerBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <header>
          <h2 className="text-uppercase common-text fw-bold text-center mt-5">
            TERM AND CONDITION
          </h2>
        </header>
        <div className="row">
          <div className="col-lg-12">
            <div className="staffing_privacy pt-3 mt-5">
              <div className="">
                <h5 className="svk_privacy_heading">
                  SVK SUPPLIERS PRIVATE LIMITED – Terms & Conditions of Trade
                  (updated December 29, 2023)
                </h5>
                <h5 className="svk_privacy_heading">Defination</h5>
                <p className="staffing_para">
                  “Consultancy” shall mean SVK SUPPLIERS PRIVATE LIMITED its
                  successors and assigns or any person acting on behalf of and
                  with the authority of SVK SUPPLIERS PRIVATE LIMITED “Client”
                  shall mean the person, firm or corporate body described as
                  such on the invoices, application for credit, quotation, work
                  authorisation or any other forms to which these terms and
                  conditions apply, and shall mean any person acting on behalf
                  of and with the authority of such person or firm or corporate
                  body. <br />
                  “Engagement” shall mean the engagement, employment or use of
                  the Candidate by the Client. <br /> “Fee” shall mean the
                  percentage of the Remuneration, which is payable by the Client
                  to the Consultancy for the Introduction of the Candidate in
                  accordance with Clause 3 of this contract. <br />{" "}
                  “Introduction(s)” shall mean the introduction of a Candidate
                  to the Client by the Consultancy where the Fee is payable only
                  upon the Candidate’s acceptance of the Client’s offer of an
                  Engagement. No prior specific instruction from the Client is
                  required to effect an Introduction. “Remuneration” shall mean
                  the aggregate gross annual taxable salary payable to or
                  receivable by the Candidate pursuant to the Engagement.
                  “Guarantor” means that person (or persons), or entity, who
                  agrees to be liable for the debts of the Client on a principal
                  debtor basis. “Services” shall mean all services supplied by
                  the Consultancy to the Client whereby Candidates are recruited
                  into temporary, contract or permanent employment in line with
                  these terms and conditions and includes any advice or
                  recommendations (and where the context so permits shall
                  include any supply of Goods as defined above). <br />{" "}
                  “Candidate” shall mean an applicant for a temporary, contract
                  or permanent position of employment introduced to the Client
                  by the Consultancy.
                </p>
              </div>
              {/* personal information */}
              <div className="my-5">
                <h5 className="svk_privacy_heading">Services</h5>
                <p className="staffing_para">
                  We will provide to you the services specified in the Proposal
                  with reasonable care and skill. <br /> You will provide us
                  with all co-operation, information and documentation
                  reasonably required for the provision of the services. <br />
                  Charges, Expenses and Payments Our fees will be agreed with
                  you in the Proposal. <br />
                  Expenses (including reprographic expenses, postage costs,
                  courier charges, facsimile and telephone charges, travel
                  expenses, parking fees, accommodation expenses, equipment
                  hire, storage costs, collection and delivery fees) incurred in
                  the course of providing the services will be passed on to you
                  at cost, and will be invoiced with our fees. <br /> We will
                  charge VAT upon our services, other than when services are
                  rendered to clients outside of the EEC. Subject to any
                  agreement to the contrary in the Proposal, <br /> we will
                  invoice you in arrears at the end of each calendar month; and
                  our invoices are payable within 7 days of the date of the
                  invoice.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  Intellectual Property Rights
                </h5>
                <p className="staffing_para">
                  For the purpose of this Clause :
                </p>
                <p className="staffing_para">
                  (a) “Materials” means the materials (including software, but
                  excluding source code (if applicable to technology)) that we
                  deliver or have a contractual obligation to deliver to you in
                  the course of providing the services;
                </p>
                <p className="staffing_para">
                  (b) “Intellectual Property Rights” means all intellectual
                  property rights throughout the world whether vested or
                  contingent and whether currently in existence or otherwise
                  including (without limitation) copyright, database rights,
                  design rights, registered designs, patents, trademarks, trade
                  names signs and other designations, trade secrets and all
                  similar rights whether registered or otherwise and all
                  extensions, revivals, reversions and renewals of any of the
                  above and, in relation to registrable rights, any applications
                  made in respect thereof; and
                </p>
                <p className="staffing_para">
                  (c) “Third Party Works” means the works and/or materials
                  comprised in the Materials, the Intellectual Property Rights
                  in which are owned by a third party (which will be specified
                  in the Proposal or otherwise agreed between the parties from
                  time to time).
                </p>
                <p className="staffing_para">
                  We hereby grant to you a worldwide, irrevocable, royalty-free,
                  non-exclusive licence under the Intellectual Property Rights
                  in the Materials (other than the Third Party Works) to copy,
                  adapt and publish the Materials, together with the right to
                  sub-license these rights. We will secure for your benefit a
                  licence, if applicable, to use the Third Party Works in the
                  manner contemplated in the Proposal.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Confidentiality</h5>
                <p className="staffing_para">
                  we will keep confidential, and will not disclose to any third
                  party, all the information you supply to us under or in
                  connection with the Agreement. This Agreement imposes no
                  obligations upon us with respect to information which is or
                  becomes publicly known through no act or default on our part.{" "}
                  <br />
                  The restrictions in this Clause [4] do not apply to the extent
                  that any information is required to be disclosed by any law or
                  regulation, judicial or governmental request or order.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  Limitations and exclusions of liability
                </h5>
                <p className="staffing_para">
                  Nothing in the Agreement will: <br />
                  (a) limit or exclude the liability of a party for death or
                  personal injury resulting from negligence; <br />
                  (b) limit or exclude the liability of a party for fraud or
                  fraudulent misrepresentation by that party; <br />
                  (c) limit any liability of a party in any way that is not
                  permitted under applicable law; or <br />
                  (d) exclude any liability of a party that may not be excluded
                  under applicable law. <br />
                  we will not be liable to you for any [loss of profits, loss of
                  business, loss of anticipated savings, loss of opportunity,
                  loss of data or any kind of <br /> indirect or consequential
                  loss, whether it arises from a breach of contract, negligence,
                  or otherwise.
                </p>
                <p className="staffing_para">
                  our total liability to you in relation to any event or series
                  of related events, whether it arises from breach of contract,
                  negligence, or otherwise, will not exceed the amount received
                  from you over the proceeding 12 months in aggregate. You
                  accept that we have an interest in limiting the personal
                  liability of our officers and employees. Having regard to that
                  interest, and subject to Clause [6.1], you accept that we are
                  a limited liability entity and agree that you will not bring
                  any claim personally against individual officers or employees
                  in respect of any losses you suffer in connection with our
                  services. The provisions of this Clause will not limit or
                  exclude the liability of the limited company itself for the
                  acts and omissions of our officers and employees.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Termination</h5>
                <p className="staffing_para">
                  This Agreement will come into force upon the earlier of our
                  receipt of your agreement to its terms and our commencing work
                  for you with your consent, and will terminate automatically
                  upon the completion of the services which we have agreed we
                  will provide to you. Either party may terminate the Agreement
                  if
                </p>
                <p className="staffing_para">
                  (a) the other party commits a material breach of the terms of
                  the Agreement; or <br />
                  (b) the other party becomes insolvent or bankrupt or enters
                  any insolvency or bankruptcy procedure in any jurisdiction.{" "}
                  <br />
                  Where the Agreement is terminated by you under Clause , we
                  will: (a) refund to you any amounts paid to us; and <br />
                  (b) release you from any obligation to pay any amounts to us,{" "}
                  <br />
                  in relation to any services which have not been provided as at
                  the effective date of termination (the amount of which will be
                  calculated by us <br />
                  using any reasonable methodology). <br />
                  Save as provided in Clause , you will not be entitled to any
                  refund of any fees paid, <br />
                  or be released from any obligation to pay our fees (whether or
                  not invoiced). <br />
                  Termination of the Agreement will not affect our or your
                  accrued rights and liabilities, or the continuing application
                  of Clauses .
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">General</h5>
                <p className="staffing_para">
                  We will have no liability to you if we are unable to provide
                  any services under the Agreement as a result of circumstances
                  beyond our control, including (without limitation) war,
                  strike, lockout, industrial disputes, riot, civil commotion,
                  acts of Government, fire, flood, blockade, accident, natural
                  catastrophe, disaster, or default of a third party.
                </p>
                <p className="staffing_para">
                  The dates for the provision of the services set out in the
                  Proposal will not be of the essence of the Agreement.
                </p>
                <p className="staffing_para">
                  No delay, neglect or forbearance in enforcing any term of the
                  Agreement by either party will be deemed to be a waiver or in
                  any way prejudice that party.
                </p>
                <p className="staffing_para">
                  Save under Clauses and , nothing in the Agreement confers on
                  any third party any right to enforce any term of the
                  Agreement.
                </p>
                <p className="staffing_para">
                  The Agreement will be governed by and construed in accordance
                  with the laws of England, and the English courts will have
                  exclusive jurisdiction to adjudicate disputes about, under or
                  in relation to the Agreement.
                </p>
                <p className="staffing_para">
                  The Agreement together with any specific terms we agree in
                  writing constitutes the entire agreement between us and you in
                  relation to the subject matter hereof, and supersedes all
                  earlier communications between us and you.
                </p>
                <p className="staffing_para">
                  Subject to Clause each party acknowledges that it has not
                  relied on any commitment, representation or warranty in
                  entering into the Agreement. brNo amendment or other variation
                  of the Agreement will be effective unless it is agreed in
                  writing by or on behalf of the parties to the Agreement.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Contact Us</h5>
                <p className="staffing_para">
                  For more information about our privacy practices, if you have
                  questions, or if you would like to make a complaint, please
                  contact our corporate office. You can reach us by e-mail at
                  inquiry@svkconsultant.com or by mail using the details
                  provided below. Additionally, feel free to contact us directly
                  at +91 8686553001 for any assistance or inquiries
                </p>
                <p className="staffing_para">
                  Pentagon Building, 401, Pune - Satara Rd, near hotel
                  panchami, Pune, Maharashtra 411009
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
