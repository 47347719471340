import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import careerBg from "../../component/asset/image/services/c1.webp";
const Current_Opening = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Looking to join our dynamic team? Explore exciting career opportunities in staff management and apply your skills in a supportive and growth-oriented environment. Join us today!"
        />
        <meta
          name="description"
          content="Staff management jobs, career opportunities, join our team, staff recruitment, professional growth"
        />
        <title>Current Opening | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <div className="current-opening">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={careerBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 mt-3 common-text">Deputy Manager</h4>
                <p>
                  Experience - 7+years | Job Location - Pune| CTC - upto 13.5
                  LPA
                </p>

                <p className="mt-5 fw-bold">
                  We seek enthusiastic individuals with:
                </p>
                <ul>
                  <li>Experience : 7+</li>
                  <li>Location : Pune</li>
                  <li>CTC : 13.5</li>
                  <li>Shifts : Night Shift</li>
                </ul>
                <p className="fw-bold">Must Have Skills:</p>
                <ul>
                  <li>
                    CA/ICWA or equivalent required with a concentration in
                    Audit, Taxation &Accounting
                  </li>
                  <li>
                    2-5 years accounting experience, Country controllership.
                  </li>
                  <li>
                    Excellent computer skills required with experience in Excel
                    must.
                  </li>
                  <li>
                    Team-oriented and customer focused with excellent
                    interpersonal skills. Must have good problem solving and
                    resolution skills.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <Link to="/form" className="btn btn-primary fs-15">
                APPLY NOW
              </Link>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 common-text mt-3">Senior Executive</h4>
                <p>
                  Exp - 3+ years | Job Location : Chennai | CTC - As per
                  industry standards
                </p>

                <p className="mt-5 fw-bold">
                  We seek enthusiastic individuals with:
                </p>
                <ul>
                  <li>Location : Chennai</li>
                  <li>Shifts : Night Shift</li>
                  <li>Exp :3+Yrs relevant</li>
                  <li>CTC : As per industry standards</li>
                </ul>
                <p className="fw-bold">Must Have Skills:</p>
                <ul>
                  <li>Strong Experience of Supply Chain Management</li>
                  <li>Should be ready to work in Night shift</li>
                  <li>Good in communication</li>
                  <li>Ability to take decision</li>
                  <li>
                    Should be able to manage client by building good
                    relationship
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <Link to="/form" className="btn btn-primary fs-15">
                APPLY NOW
              </Link>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 common-text mt-3">.NET Architect</h4>
                <p>
                  Exp - 8 to 12 years | Job Location : Pune or Remote| CTC -
                  upto 30 LPA
                </p>

                <p className="mt-5 fw-bold">
                  We seek enthusiastic individuals with:
                </p>
                <ul>
                  <li>Experience : 8 – 12 yrs</li>
                  <li>Budget : 30 LPA</li>
                  <li>Job location : Pune or Remote</li>
                  <li>Shifts : Night Shift</li>
                </ul>
                <p className="fw-bold">Must Have Skills:</p>
                <ul>
                  <li>
                    Has prior application development background and working
                    experience on:
                  </li>
                  <li>Angular v11</li>
                  <li>C# MVC</li>
                  <li>Dot Net Core 5.1</li>
                  <li>Typescript v4.1.5</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <Link to="/form" className="btn btn-primary fs-15">
                APPLY NOW
              </Link>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 common-text mt-3">
                  Technical Consultant (MS Dynamics)
                </h4>
                <p>
                  Exp - 8 to 12 years | Job Location : Bangalore | CTC - upto 25
                  LPA
                </p>

                <p className="mt-5 fw-bold">
                  We seek enthusiastic individuals with:
                </p>
                <ul>
                  <li>Experience : 8 – 12 yrs</li>
                  <li>Budget : 25 LPA</li>
                  <li>Job location : Bangalore</li>
                  <li>Shifts : Night Shift</li>
                </ul>
                <p className="fw-bold">Must Have Skills:</p>
                <ul>
                  <li>
                    Ability to understand and to update existing applications
                    (Azure Web Job / Azure WebApp / ASP.NET MVC) in .NET
                    framework 4.0/4.5, C#
                  </li>
                  <li>
                    Minimum of 3 years proven working experience in .NET
                    programming
                  </li>
                  <li>
                    Minimum of 2 years proven working experience with Web
                    Languages such as HTML, JavaScript & CSS Minimum of 6 months
                    of relevant experience in Microsoft Power Platform
                  </li>
                  <li>
                    Minimum of 6 months of experience in Dynamic 365
                    administration
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <Link to="/form" className="btn btn-primary fs-15">
                APPLY NOW
              </Link>
            </div>
            <hr className="mt-3" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Current_Opening;
