import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./UserPanel.css";
import UserHeader from "../../component/Header/UserPanelHeader/UserHeader";
import UserFooter from "../../component/Footer/UserPanelFooter/UserFooter";

const UserPanel = () => {
  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      console.log("Please Log In First");
      navigate("/login");
    }
  }, []);

  return (
    <>
      <UserHeader />
      <section className="py-5 mt-5 mb-5">
        <div className="user-panel-second">
          <div className="container">
            <div className="col-lg-12">
              <div className="user-panel-top mb-5 text-center mt-5">
                <h1 className="fw-bold text-success">
                  Thank You for Login.....
                </h1>
                {/* <button className="btn btn-danger">
                  Log Out
                </button> */}
              </div>
            </div>
            <div className="col-lg-12 mt-5">
              <div className="card hall-ticket-main">
                <div className="card-body hall-ticket-body">
                  <div className="hall-ticket-title d-flex justify-content-between align-items-center text-center">
                    <h5 className="fw-bold mt-3  common-text">
                      Your Hall Ticket Will Be Generated Please Download Now
                    </h5>
                    {/* <button className="btn btn-success" onClick={onButtonClick}>
                      Download
                    </button> */}
                    <Link
                      to={userDetails.hall_ticket}
                      target="_blank"
                      className="btn btn-success"
                      download
                    >
                      Download
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UserFooter />
    </>
  );
};

export default UserPanel;
