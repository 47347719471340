/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "../Footer.css";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
const UserFooter = () => {
  return (
    <>
      <div className="common-btn-betch">
        <Link to="/form">
          <img
            src={require("../../../component/asset/image/registernow.png")}
            alt="enquiry-now"
            className="img-fluid"
          />
        </Link>
      </div>

      <div className="sidenav">
        <a
          href="https://api.whatsapp.com/send?phone=8686553001"
          className="whatapp-chat"
          target="_blank"
          style={{ cursor: "pointer" }}
        >
          <WhatsAppIcon />
        </a>
        <a
          href="mailto:vijaykhandekar3001@gmail.com"
          className="mail-chat"
          target="_blank"
          style={{ cursor: "pointer" }}
        >
          <MailIcon style={{ width: "1.3rem" }} />
        </a>
        <a
          href="Tel:8686553001"
          className="phone-chat"
          style={{ cursor: "pointer" }}
        >
          <PhoneIcon style={{ width: "1.3rem" }} />
        </a>
      </div>
      <footer className="text-center text-lg-start footer-main text-muted">
        <section className="d-flex social-footer-sec justify-content-center align-items-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span className="fw-bold text-uppercase text-dark">
              Get connected with us on social networks:
            </span>
          </div>
          <div>
            <Link to="" className="me-4 text-reset">
              {/* <FacebookIcon  style={{color:"#3b5998"}}/> */}
              <img
                src={require("../../../component/asset/image/footer/facebook.png")}
                alt="facebook"
                width={30}
              />
            </Link>
            <Link to="" className="me-4 text-reset">
              {/* <TwitterIcon style={{color:"#00acee"}} /> */}
              <img
                src={require("../../../component/asset/image/footer/google.png")}
                alt="facebook"
                width={30}
              />
            </Link>
            <Link to="" className="me-4 text-reset">
              {/* <GoogleIcon style={{color:"#0F9D58"}} /> */}
              <img
                src={require("../../../component/asset/image/footer/insta.png")}
                alt="facebook"
                width={30}
              />
            </Link>
            <Link to="" className="me-4 text-reset">
              {/* <InstagramIcon style={{color:" #4f5bd5"}} /> */}
              <img
                src={require("../../../component/asset/image/footer/linkdin.png")}
                alt="facebook"
                width={28}
              />
            </Link>
            <Link to="" className="me-4 text-reset">
              {/* <LinkedInIcon style={{color:"#3b5998"}} /> */}
              <img
                src={require("../../../component/asset/image/footer/twitter.png")}
                alt="facebook"
                width={30}
              />
            </Link>
          </div>
        </section>
        <section className>
          <div className="container text-center text-md-start mt-5">
            <div className="row text-white mt-3">
              <div className="col-md-6 col-lg-6 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  {" "}
                  SVK SUPPLIERS PRIVATE LIMITED
                </h6>
                <p>
                  SVK SUPPLIERS PRIVATE LIMITED is a critical function that lies
                  at the heart of a successful organization. It involves
                  overseeing the entire lifecycle of employees, from recruitment
                  and onboarding to performance management and development.
                </p>
              </div>
              {/* <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Services</h6>
                <p>
                  <Link to="/recruitment" className="text-reset text-decoration-none footer-links">
                    Recruitement
                  </Link>
                </p>
                <p>
                  <Link to="/training" className="text-reset text-decoration-none footer-links">
                    Training
                  </Link>
                </p>
                <p>
                  <Link to="/job_fair" className="text-reset text-decoration-none footer-links">
                    Job Fair
                  </Link>
                </p>
                <p>
                  <Link to="/staff_services" className="text-reset text-decoration-none footer-links">
                    Staff Services
                  </Link>
                </p>
              </div> */}
              {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <Link to="/" className="text-reset text-decoration-none footer-links">
                    Home
                  </Link>
                </p>
                <p>
                  <Link to="/about" className="text-reset text-decoration-none footer-links">
                    About
                  </Link>
                </p>
                <p>
                  <Link to="/current_opening" className="text-reset text-decoration-none footer-links">
                    Careers
                  </Link>
                </p>
                <p>
                  <Link to="/contact" className="text-reset text-decoration-none footer-links">
                    Contact
                  </Link>
                </p>
              </div> */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p className="footer-links">
                  <HomeIcon />
                  <span className="ms-2"> Pentagon Building, 401, Pune - Satara Rd, near hotel panchami, Pune, Maharashtra 411009</span>
                </p>
                <p className="footer-links">
                  <EmailIcon />
                  <span className="ms-2" style={{ fontSize: "14px" }}>
                  vijaykhandekar3001@gmail.com
                  </span>
                </p>
                <p className="footer-links">
                  <LocalPhoneIcon />
                  <span className="ms-2">+91 8686553001</span>
                </p>
                {/* <p>
                <PrintIcon /> <span className="ms-2">+ 01 234 567 89</span>
              </p> */}
              </div>
            </div>
          </div>
        </section>
        <div
          className="text-center footer-micro text-white p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2023 SVK SUPPLIERS PRIVATE LIMITED Designed By
          <Link
            className="text-reset  ms-2 fw-bold"
            target="_blank"
            to="https://www.microdynamicsoftware.com/"
          >
            Microdynamic Software Private Limited
          </Link>
        </div>
      </footer>
    </>
  );
};

export default UserFooter;
