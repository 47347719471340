import React from "react";
import ContentDevelopmentLastSec from "../../../component/asset/image/services/labour_healthcare.webp";
import { Helmet } from "react-helmet";
import jobBg from "../../../component/asset/image/services/medical_banner.webp";
const Labour_healthcare = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="staff management, job fair, career opportunities, hiring event, job openings, recruitment, employment, job seekers, workforce, job fair registration"
        />
        <meta
          name="description"
          content="Discover exciting career opportunities at our Staff Management Job Fair. Join us for a day of networking and exploration as top companies present their job openings. Register now to connect with employers and take your career to new heights."
        />
        <title>Job Fair | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <section className="content-development">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active content-development-bg">
              <img src={jobBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
        <div className="content-development-second-sec mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="content-development-top-sec">
                  <h3 className="text-uppercase common-text">
                    Associate Fellow of Industrial Health (AFIH)
                  </h3>
                  <hr className="heading_line" />
                  <div className="content-development-common-sec">
                    <p className="content-development-left-para mb-4">
                      A medical check-up for labor and employees, often referred
                      to as a pre-employment or occupational health examination,
                      is a crucial process in many industries and workplaces to
                      ensure the health and fitness of workers. The specific
                      requirements and procedures for such examinations may vary
                      depending on the industry, job roles, and local
                      regulations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-dev-left-sec">
                  <span className="training-span common-text mt-2">
                    Purpose:
                  </span>
                  <br />
                  <span className="training-span mt-2">Medical History</span>
                  <p className="content-development-left-para mb-4">
                    Employees are usually required to provide a detailed medical
                    history, including information about past illnesses,
                    surgeries, medications, allergies, and lifestyle factors
                    (e.g., smoking, alcohol consumption).
                  </p>
                  <span className="training-span mt-2">
                    Physical Examination
                  </span>
                  <p className="content-development-left-para mb-4">
                    A comprehensive physical examination is conducted by a
                    qualified healthcare professional. This may include checks
                    for vision, hearing, blood pressure, heart rate, lung
                    function, and general physical health.
                  </p>
                  <span className="training-span mt-2">Laboratory Tests</span>
                  <p className="content-development-left-para mb-4">
                    Depending on the job and industry, various medical tests may
                    be conducted, such as blood tests (e.g., for blood count,
                    cholesterol levels, or drug screening), urine tests, and
                    tests for infectious diseases (e.g., tuberculosis).
                  </p>
                  <span className="training-span mt-2">
                    Drug and Alcohol Testing
                  </span>
                  <p className="content-development-left-para mb-4">
                    Some industries, especially those involving safety-critical
                    roles (e.g., heavy machinery operation), require drug and
                    alcohol screening to ensure a drug-free workplace.
                  </p>
                  <span className="training-span mt-2">
                    Mental Health Assessment
                  </span>
                  <p className="content-development-left-para mb-4">
                    Certain positions may require a psychological evaluation to
                    assess an individual's mental and emotional stability.
                  </p>
                  <span className="training-span mt-2">Vaccination Status</span>
                  <p className="content-development-left-para mb-4">
                    Verification of up-to-date vaccinations, especially for
                    roles in healthcare, where exposure to infectious diseases
                    is a concern.
                  </p>
                  <span className="training-span mt-2">
                    Functional Capacity Evaluation
                  </span>
                  <p className="content-development-left-para mb-4">
                    For physically demanding roles, a functional capacity
                    evaluation may be conducted to assess an individual's
                    ability to perform job-specific tasks.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-development-right-sec my-5">
                  <img
                    src={ContentDevelopmentLastSec}
                    className="img-fluid rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Labour_healthcare;
