/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Carousel } from "react-bootstrap";
import "./AutoSlider.css"; // Create this CSS file for custom styling

const AutoSlider = () => {
  return (
    <div className="slider-container-fluid mt-5">
      <center>
        <h4 className="text-center common-text">HAPPY CLIENTS</h4>
        <hr className="heading_line" style={{ Color: "#ffffff" }} />
      </center>
      <Carousel interval={3000} pauseOnHover>
        <Carousel.Item>
          {/* Top Text */}
          <div className="carousel-top-text">
            <p>
              "I am thrilled to share my wonderful experience with the SVK
              SUPPLIERS PRIVATE LIMITED website! As a business owner, I have
              used various management tools and platforms in the past, but none
              have come close to the efficiency and effectiveness of this
              website."
            </p>
            <p className="text-center mt-2">
              By CEO - An Microdynamic Software Company
            </p>
          </div>

          {/* Bottom Image */}
          <div className="carousel-bottom-image text-center mb-5">
            <img
              className="img-fluid rounded-circle"
              src={require("../../component/asset/image/m-profile.jpg")}
              alt="Image 2"
              width={40}
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          {/* Top Text */}
          <div className="carousel-top-text">
            <p>
              " Best guidence got by Dr.Avinash Sir and Thank you for helping me
              to decide that which college is best for my career."
            </p>
            <h6 className="text-center fw-bold mt-2">Dipti Kute</h6>
          </div>

          {/* Bottom Image */}
          <div className="carousel-bottom-image text-center mb-5">
            <img
              className="img-fluid"
              src={require("../../component/asset/image/d-profile.png")}
              alt="Image 2"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          {/* Top Text */}
          <div className="carousel-top-text">
            <p>
              "Nice staff and very good experience my sun given admission in
              alwas homeopathy medical College moodbiddari thanks admission Idea
              team"
            </p>
            <h6 className="text-center fw-bold mt-2">SHUBHAM LAWATE</h6>
          </div>

          {/* Bottom Image */}
          <div className="carousel-bottom-image text-center mb-5">
            <img
              className="img-fluid"
              src={require("../../component/asset/image/s-profile.png")}
              alt="Image 2"
            />
          </div>
        </Carousel.Item>
        {/* Add more Carousel.Item as needed */}
      </Carousel>
    </div>
  );
};

export default AutoSlider;
