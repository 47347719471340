import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import careerBg from "../../component/asset/image/carriers.png";
import "./Privacy.css";
const Privacy_Policy = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Looking to join our dynamic team? Explore exciting career opportunities in staff management and apply your skills in a supportive and growth-oriented environment. Join us today!"
        />
        <meta
          name="description"
          content="Staff management jobs, career opportunities, join our team, staff recruitment, professional growth"
        />
        <title>Privacy Policy | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <div className="current-opening">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={careerBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <header>
          <h2 className="text-uppercase common-text fw-bold text-center mt-5">
            Privacy Policy
          </h2>
        </header>
        <div className="row">
          <div className="col-lg-12">
            <div className="staffing_privacy pt-3 mt-5">
              <div className="">
                <h5 className="svk_privacy_heading">
                  SVK SUPPLIERS PRIVATE LIMITED Academy Privacy Policy (updated
                  December 29, 2023)
                </h5>
                <p className="staffing_para">
                  This Privacy Policy describes how your personal information is
                  collected, used, and shared when you visit or make a purchase
                  from https://svkconsultant.com/ (the “Site”) and any all
                  subsidiary sites including
                  https://svkconsulting.com/ Roleplay
                  Workshop, A Day in The Life of a Staffingpreneur, Million
                  Dollar Playbook, 7-Figure Sales Masterclass, 6-Figure Resume
                  Writing Biz, Rookie Recruiter Training, How To Get Staffing
                  Clients, A Crash Course in Starting a Recruitment & Staffing
                  Business,  svkconsulting.com.
                </p>
              </div>
              {/* personal information */}
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  Personal Information We Collect
                </h5>
                <p className="staffing_para">
                  When you visit the Site(s), we automatically collect certain
                  information about your device, including information about
                  your web browser, IP address, time zone, and some of the
                  cookies that are installed on your device. Additionally, as
                  you browse the Site(s), we collect information about the
                  individual web pages or products that you view, what websites
                  or search terms referred you to the Site(s), and information
                  about how you interact with the Site. We refer to this
                  automatically-collected information as “Device Information.”
                  We collect Device Information using the following
                  technologies: – “Cookies” are data files that are placed on
                  your device or computer and often include an anonymous unique
                  identifier. For more information about cookies, and how to
                  disable cookies, visit http://www.svkconsultant.com – “Log
                  files” track actions occurring on the Site, and collect data
                  including your IP address, browser type, Internet service
                  provider, referring/exit pages, and date/time stamps. – “Web
                  beacons,” “tags,” and “pixels” are electronic files used to
                  record information about how you browse the Site.
                  Additionally, when you make a purchase or attempt to make a
                  purchase through the Site(s), we collect certain information
                  from you, including your name, billing address, shipping
                  address, payment information (including credit card numbers),
                  email address, and phone number. We refer to this information
                  as “Order Information.” When we talk about “Personal
                  Information” in this Privacy Policy, we are talking both about
                  Device Information and Order Information.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  How Do We Use Your Personal Information?
                </h5>
                <p className="staffing_para">
                  We use the Order Information that we collect generally to
                  fulfill any orders placed through the Site (including
                  processing your payment information, arranging for shipping,
                  and providing you with invoices and/or order confirmations).
                  Additionally, we use this Order Information to: Communicate
                  with you; Screen our orders for potential risk or fraud; and
                  When in line with the preferences you have shared with us,
                  provide you with information or advertising relating to our
                  products or services. We use the Device Information that we
                  collect to help us screen for potential risk and fraud (in
                  particular, your IP address), and more generally to improve
                  and optimize our Site (for example, by generating analytics
                  about how our customers browse and interact with the Site, and
                  to assess the success of our marketing and advertising
                  campaigns).
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  Sharing Your Personal Information
                </h5>
                <p className="staffing_para">
                  We share your Personal Information with third parties to help
                  us use your Personal Information, as described above. For
                  example, we use WooCommerce to power our online store–you can
                  read more about how WooCommerce uses your Personal Information
                  here: https://automattic.com/privacy/. We also use Google
                  Analytics to help us understand how our customers use the
                  Site–you can read more about how Google uses your Personal
                  Information here: https://www.google.com/
                  intl/en/policies/privacy/. You can also opt-out of Google
                  Analytics here: https://tools.google.com /dlpage/gaoptout. We
                  also use ClickFunnels to power some of our sales pages and
                  membership sites–you can read more about how ClickFunnels uses
                  your personal information here:
                  https://signup.clickfunnels.com/privacy-policy. We also use
                  PayPal and Stripe as payment processing systems and you can
                  view their privacy policy to learn more about how they are
                  using your information here: Paypal’s Privacy Policy and
                  Stripe’s Privacy Policy. Finally, we may also share your
                  Personal Information to comply with applicable laws and
                  regulations, to respond to a subpoena, search warrant or other
                  lawful request for information we receive, or to otherwise
                  protect our rights.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Behavioral Advertising</h5>
                <p className="staffing_para">
                  As described above, we use your Personal Information to
                  provide you with targeted advertisements or marketing
                  communications we believe may be of interest to you. For more
                  information about how targeted advertising works, you can
                  visit the Network Advertising Initiative’s (“NAI”) educational
                  page at http://www.networkadvertising.org
                  /understanding-online-advertising/how-does-it-work.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  You can opt out of targeted advertising by:
                </h5>
             
                <p className="staffing_para">
                  Additionally, you can opt out of some of these services by
                  visiting the Digital Advertising Alliance’s opt-out portal at:
                  http://optout.aboutads.info/.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Do Not Track :</h5>
                <p className="staffing_para">
                  Please note that we do not alter our Site’s data collection
                  and use practices when we see a Do Not Track signal from your
                  browser.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Your Rights </h5>
                <p className="staffing_para">
                  If you are a European resident, you have the right to access
                  personal information we hold about you and to ask that your
                  personal information be corrected, updated, or deleted. If you
                  would like to exercise this right, please contact us through
                  the contact information below. Additionally, if you are a
                  European resident we note that we are processing your
                  information in order to fulfill contracts we might have with
                  you (for example if you make an order through the Site), or
                  otherwise to pursue our legitimate business interests listed
                  above. Additionally, please note that your information will be
                  transferred outside of Europe, including to Canada and the
                  United States.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Data Retention</h5>
                <p className="staffing_para">
                  When you place an order through the Site, we will maintain
                  your Order Information for our records unless and until you
                  ask us to delete this information.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  COPPA (Children Online Privacy Protection Act)
                </h5>
                <p className="staffing_para">
                  When it comes to the collection of personal information from
                  children under the age of 13 years old, the Children’s Online
                  Privacy Protection Act (COPPA) puts parents in control. The
                  Federal Trade Commission, United States’ consumer protection
                  agency, enforces the COPPA Rule, which spells out what
                  operators of websites and online services must do to protect
                  children’s privacy and safety online. We do not specifically
                  market to children under the age of 13 years old.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  Fair Information Practices
                </h5>
                <p className="staffing_para">
                  The Fair Information Practices Principles form the backbone of
                  privacy law in the United States and the concepts they include
                  have played a significant role in the development of data
                  protection laws around the globe. Understanding the Fair
                  Information Practice Principles and how they should be
                  implemented is critical to comply with the various privacy
                  laws that protect personal information. In order to be in line
                  with Fair Information Practices we will take the following
                  responsive action, should a data breach occur:
                </p>
                <p className="staffing_para">We will notify you via email</p>
                <ul>
                  <li className="staffing_para"> Within 7 business days</li>
                </ul>
                <p className="staffing_para">
                  We will notify the users via in-site notification
                </p>
                <ul>
                  <li className="staffing_para"> Within 7 business days</li>
                </ul>
                <p className="staffing_para">
                  We also agree to the Individual Redress Principle which
                  requires that individuals have the right to legally pursue
                  enforceable rights against data collectors and processors who
                  fail to adhere to the law. This principle requires not only
                  that individuals have enforceable rights against data users,
                  but also that individuals have recourse to courts or
                  government agencies to investigate and/or prosecute
                  non-compliance by data processors.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">CAN SPAM Act</h5>
                <p className="staffing_para">
                  The CAN-SPAM Act is a law that sets the rules for commercial
                  email, establishes requirements for commercial messages, gives
                  recipients the right to have emails stopped from being sent to
                  them, and spells out tough penalties for violations. We
                  collect your email address in order to:
                </p>
                <ul>
                  <li className="staffing_para">
                    {" "}
                    Send information, respond to inquiries, and/or other
                    requests or questions
                  </li>
                  <li className="staffing_para">
                    Process orders and to send information and updates
                    pertaining to orders.
                  </li>
                  <li className="staffing_para">
                    Send you additional information related to your product
                    and/or service
                  </li>
                  <li className="staffing_para">
                    Market to our mailing list or continue to send emails to our
                    clients after the original transaction has occurred.
                  </li>
                </ul>
                <p className="staffing_para">
                  To be in accordance with CANSPAM, we agree to the following:
                </p>
                <ul>
                  <li className="staffing_para">
                    Not use false or misleading subjects or email addresses.
                  </li>
                  <li className="staffing_para">
                    Identify the message as an advertisement in some reasonable
                    way
                  </li>
                  <li className="staffing_para">
                    Include the physical address of our business or site
                    headquarters.
                  </li>
                  <li className="staffing_para">
                    Monitor third-party email marketing services for compliance,
                    if one is used.
                  </li>
                  <li className="staffing_para">
                    Honor opt-out/unsubscribe requests quickly.
                  </li>
                  <li className="staffing_para">
                    Allow users to unsubscribe by using the link at the bottom
                    of each email.
                  </li>
                </ul>
                <p className="staffing_para">
                  If at any time you would like to unsubscribe from receiving
                  future emails, you can email us at inquiry@svkconsultant.com.
                </p>
                <ul>
                  <li className="staffing_para">
                    Follow the instructions at the bottom of each email and we
                    will promptly remove you from ALL correspondence.
                  </li>
                </ul>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">
                  Business Transfers and Acquisitions
                </h5>
                <p className="staffing_para">
                  Identifize Consulting on behalf of Staffingpreneurs Academy
                  may sell, transfer or otherwise share some or all of its
                  assets, including your personally identifiable information, in
                  connection with a merger, acquisition, reorganization or sale
                  of assets or in the event of bankruptcy. You will have the
                  opportunity to opt-out of any such transfer if the new
                  entity’s planned processing of your information differs
                  materially from that set forth in this Privacy Policy.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Changes</h5>
                <p className="staffing_para">
                  We may update this privacy policy from time to time in order
                  to reflect, for example, changes to our practices or for other
                  operational, legal or regulatory reasons.
                </p>
              </div>
              <div className="my-5">
                <h5 className="svk_privacy_heading">Contact Us</h5>
                <p className="staffing_para">
                  For more information about our privacy practices, if you have
                  questions, or if you would like to make a complaint, please
                  contact our corporate office. You can reach us by e-mail at
                  inquiry@svkconsultant.com or by mail using the details
                  provided below. Additionally, feel free to contact us directly
                  at +91 8686553001 for any assistance or inquiries
                </p>
                <p className="staffing_para">
                  Pentagon Building, 401, Pune - Satara Rd, near hotel
                  panchami, Pune, Maharashtra 411009
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy_Policy;
