import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../Header.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../component/asset/image/logo.png";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import LogoutIcon from '@mui/icons-material/Logout';

function UserHeader() {
  const navigate = useNavigate();
  //   const [activeLink, setActiveLink] = useState("");

  //   const handleLinkClick = (link) => {
  //     setActiveLink(link);
  //   };

  const logOut = () => {
    sessionStorage.removeItem("info");
    toast.success("Log Out Success");
    navigate("/");
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="bg_Color navbar-main">
      <Container fluid>
        {/* <Navbar.Brand href="#home" className='text-white fw-bold'>Staff Management</Navbar.Brand> */}
        <Link to="#">
          <img src={logo} className="mb-2 ms-2" width={250} alt="logo" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="fs_nav">
            {/* <Nav className="me-5">
              <Nav.Link
                className={`${
                  activeLink === "home" ? "active login-nav home_btn" : ""
                }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("home")}
                  to="/"
                >
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${
                  activeLink === "about" ? "active login-nav" : ""
                }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("about")}
                  to="/about"
                >
                  About Us
                </Link>
              </Nav.Link>
              <NavDropdown
                title="Services"
                id="collasible-nav-dropdown"
                className={`${
                  activeLink === "services"
                    ? "active login-nav login-link"
                    : "login-link"
                }`}
              >
                <NavDropdown.Item className="login-item">
                  <Link
                    className="login-link"
                    onClick={() => handleLinkClick("services")}
                    to="/recruitment"
                  >
                    Recruitment
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="login-link"
                    onClick={() => handleLinkClick("services")}
                    to="/training"
                  >
                    Training
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="login-link"
                    onClick={() => handleLinkClick("services")}
                    to="/job_fair"
                  >
                    Job Fair
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="login-link"
                    onClick={() => handleLinkClick("services")}
                    to="/staff_services"
                  >
                    Staff Services
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                className={`${
                  activeLink === "career" ? "active login-nav" : ""
                }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("career")}
                  to="/current_opening"
                >
                  Careers
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${
                  activeLink === "contact" ? "active login-nav" : ""
                }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("contact")}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </Nav.Link>
            </Nav> */}
            <div>
              {/* <button
                type="button"
                className="btn btn-danger"
                onClick={logOut}
              >
                Log Out
              </button> */}
              <Button
                variant="contained"
                color="error"
                endIcon={<LogoutIcon />}
                onClick={logOut}
                style={{ lineHeight: "10px" }}
              >
                log out
              </Button>
              <ToastContainer
                position={"top-center"}
                autoClose={2000}
                pauseOnHover={false}
                closeOnClick={true}
              />
              {/* <Link
                to="/registration_form"
                type="button"
                className="btn contact-btn ms-3"
              >
                Register Now
              </Link> */}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default UserHeader;
