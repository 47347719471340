import React, { useState } from "react";
import "./form.css";
import pdfView from "../../component/asset/image/latest_pdf.pdf";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect } from "react";
pdfjs.GlobalWorkerOptions.workerSrc =
  "path-to-pdfjs/2.9.359/build/pdf.worker.min.js";
const NewForm = ({ pdfUrl }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const navigate = useNavigate();
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isChecked) {
      navigate("/registration_form");
    } else {
      toast.error("कृपया अटी व शर्ती स्वीकारा.");
    }
  };

  useEffect(() => {
    // Specify the path to the PDF.js worker script
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <section className="container pdf_sec mb-5">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-center flex-column align-items-center">
          <div className="pdf-container">
            <Document file={pdfView} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={window.innerWidth >= 768 ? 800 : 350}
                />
              ))}
            </Document>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="next-form mt-5 text-center">
            <form onSubmit={handleSubmit}>
              <label className="fw-bold fs-5">
                <input
                  type="checkbox"
                  checked={isChecked}
                  className="me-2 check-btn"
                  onChange={handleCheckboxChange}
                />
                मला सर्व नियम व अटी मान्य आहेत
              </label>
              <br />
              <button className="btn btn-form-next mt-3" type="submit">
                Submit
              </button>
              <ToastContainer
                position={"top-center"}
                autoClose={2000}
                pauseOnHover={false}
                closeOnClick={true}
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewForm;
