import React, { useState } from "react";
import "./Contact.css";
import contactBg from "../../component/asset/image/services/contact.webp";
import contactLogo from "../../component/asset/image/svk-suppliers-logo.png";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import PrintIcon from "@mui/icons-material/Print";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
const Contact = () => {
  const navigate = useNavigate();
  // const [contactInfo,setContactInfo]=useState([])
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    subject: "",
    number: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    number: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContactData({
      ...contactData,
      [name]: value,
    });

    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      email: "",
      subject: "",
      number: "",
      message: "",
    };

    // Name validation
    const nameRegex = /^[a-zA-Z\s]+$/;

    if (!contactData.name.trim()) {
      newErrors.name = "Name is required *";
      valid = false;
    } else if (!nameRegex.test(contactData.name)) {
      newErrors.name = "Name Must Be Only Alphabets";
      valid = false;
    } else if (contactData.name.length <= 3) {
      newErrors.name = "Name Must Be at Least 3 Letters *";
      valid = false;
    }

    // Email validation
    if (!contactData.email.trim()) {
      newErrors.email = "Email is required *";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(contactData.email)) {
      newErrors.email = "Email is invalid *";
      valid = false;
    }

    // Subject validation
    if (!contactData.subject.trim()) {
      newErrors.subject = "Subject is required *";
      valid = false;
    }

    // Contact number validation
    if (!contactData.number.trim()) {
      newErrors.number = "Contact Number is required *";
      valid = false;
    } else if (contactData.number.length !== 10) {
      newErrors.number = "Number Must Be 10 Digits *";
      valid = false;
    }

    // Message validation
    if (!contactData.message.trim()) {
      newErrors.message = "Message is required *";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  // handle form data on submit

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, email, subject, number, message } = contactData;
    if (validateForm()) {
      try {
        const formData = {
          name: name,
          email: email,
          subject: subject,
          mobile: number,
          message: message,
        };
        const response = await axios.post(
          `${API_BASE_URL}user/contact_enquiry`,
          formData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        if (response.data.status) {
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error In Seller Response:", error.response.data.error);
      }
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="keywords"
          content="staff management, contact us, workforce management, employee support, workforce solutions, staff queries, staff feedback, HR contact"
        />
        <meta
          name="description"
          content="Contact our staff management team for any inquiries, feedback, or support. We are here to assist you with workforce management solutions, employee queries, and more."
        />
        <title>Contact Us | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active contact_bg">
            <img src={contactBg} className="d-block w-100" alt="..." />
          </div>
        </div>
      </div>
      <div className="contact-main mb-5">
        <div className="container">
          <div className="row">
            <header>
              <h4 className="text-uppercase common-text fw-bold text-center mt-5 mb-5">
                send message
              </h4>
            </header>
            <div className="col-lg-6 d-flex  justify-content-center align-items-center ">
              <div className="contact-left-sec">
                <div className="contact-img-left">
                  <img
                    src={contactLogo}
                    className="ms-4 img-fluid"
                    alt="contact-logo"
                  />
                </div>
                <div className="contact-info-left">
                  <ul>
                    <li className="contact-info-para mt-3">
                      <p className="me-3">
                        <HomeIcon color="primary" />
                      </p>
                      <span className="ms-2">
                        Pentagon Building, 401, Pune - Satara Rd, near
                        hotel panchami, Pune, Maharashtra 411009
                      </span>
                    </li>
                    <li className="contact-info-para">
                      <p className="me-3">
                        <EmailIcon color="primary" />
                      </p>
                      <span className="ms-2 ps-1">
                        vijaykhandekar3001@gmail.com
                      </span>
                    </li>
                    <li className="contact-info-para">
                      <p className="me-3">
                        <LocalPhoneIcon color="primary" />
                      </p>
                      <span className="ms-2">+91 8686553001</span>
                    </li>
                    {/* <li className="contact-info-para">
                      <p className="me-3">
                        <PrintIcon color="primary" />
                      </p>
                      <span className="ms-2">+ 9022552182</span>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form">
                <div className="card contact-card">
                  <div className="card-body contact-body">
                    {/* contact form */}
                    <form onSubmit={handleSubmit}>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 mb-4">
                            <h4 className="fw-bold common-text">Contact Now</h4>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                value={contactData.name}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Name"
                              />
                              {errors.name && (
                                <div className="error ms-2 text-danger">
                                  {errors.name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                value={contactData.email}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Email"
                              />
                              {errors.email && (
                                <div className="error ms-2 text-danger">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="text"
                                name="subject"
                                value={contactData.subject}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Subject"
                              />
                              {errors.subject && (
                                <div className="error ms-2 text-danger">
                                  {errors.subject}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="number"
                                name="number"
                                value={contactData.number}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Contact Number"
                              />
                              {errors.number && (
                                <div className="error ms-2 text-danger">
                                  {errors.number}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 mb-4">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                placeholder="Message"
                                name="message"
                                value={contactData.message}
                                onChange={handleChange}
                                id=""
                                cols="30"
                                rows="6"
                              ></textarea>
                              {errors.message && (
                                <div className="error ms-2 text-danger">
                                  {errors.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <button className="btn text-uppercase contact-btn">
                                Send Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ToastContainer
                        position={"top-center"}
                        autoClose={2000}
                        pauseOnHover={false}
                        closeOnClick={true}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
