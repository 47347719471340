import React from "react";
import logo from "../../component/asset/image/logo.png";
import "./Razorpay.css";
import axios from "axios";

function RazorpayMain() {
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(amount) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: "rzp_live_BDRl6BUgC0RF7G", // Enter the Key ID generated from the Dashboard
      currency: "INR",
      amount: "300",
      name: " SVK SUPPLIERS PRIVATE LIMITED",
      description: "Thanks For Purchasing",
      image: "../../component/asset/image/logo.png",

      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert("payment successful");
      },
      prefill: {
        name: "Microdynamic",
        email: "info@gmail.com",
        contact: "9999999999",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div className="razorpay-main">
      <header className="razorpay-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h5>Payment Now</h5>
        <button className="App-link btn btn-dark" onClick={displayRazorpay}>
          Pay ₹300
        </button>
      </header>
    </div>
  );
}

export default RazorpayMain;
