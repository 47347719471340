import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../../component/asset/image/svk-suppliers-logo.png";
import { useState } from "react";

function Header() {
  const [activeLink, setActiveLink] = useState("");
  const [expanded, setExpanded] = useState(false);
  const location = useLocation("");
  const handleLinkClick = (link) => {
    setActiveLink(link); // Set the active link when a link is clicked
    setExpanded(false); // Close the Navbar menu
  };
  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg_Color navbar-main"
      expanded={expanded}
      onToggle={handleNavbarToggle}
    >
      <Container fluid>
        {/* <Navbar.Brand href="#home" className='text-white fw-bold'>Staff Management</Navbar.Brand> */}
        <Link to="/">
          <img src={logo} className="mb-2 img-fluid ms-2" alt="logo" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="fs_nav">
            <Nav className="me-5">
              <Nav.Link
                className={`${
                  location.pathname === "/" ? "active login-nav home_btn" : ""
                  // activeLink === "home" ? "active login-nav home_btn" : ""
                }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("home")}
                  to="/"
                >
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${
                  location.pathname === "/about"
                    ? "active login-nav home_btn"
                    : ""
                  // activeLink === "about" ? "active login-nav" : ""
                }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("about")}
                  to="/about"
                >
                  About Us
                </Link>
              </Nav.Link>
              <NavDropdown
                title="Services"
                id="collasible-nav-dropdown"
                className={`${
                  location.pathname === "/recruitment" ||
                  location.pathname === "/training" ||
                  location.pathname === "/job_fair" ||
                  location.pathname === "/staff_services" ||
                  location.pathname === "/labour_healthcare"
                    ? "active login-nav login-link"
                    : "login-link"
                  // activeLink === "services"
                  //   ? "active login-nav login-link"
                  //   : "login-link"
                }`}
              >
                <NavDropdown.Item className="login-item">
                  <Link
                    className="login-link"
                    onClick={() => handleLinkClick("services")}
                    to="/recruitment"
                  >
                    Recruitment
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="login-link"
                    onClick={() => handleLinkClick("services")}
                    to="/training"
                  >
                    Training
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="login-link"
                    onClick={() => handleLinkClick("services")}
                    to="/job_fair"
                  >
                    Job Fair
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="login-link"
                    onClick={() => handleLinkClick("services")}
                    to="/staff_services"
                  >
                    Staff Services
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="login-link"
                    onClick={() => handleLinkClick("services")}
                    to="/labour_healthcare"
                  >
                    Labour Healthcare
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                className={`${
                  location.pathname === "/current_opening"
                    ? "active login-nav"
                    : ""
                  // activeLink === "career" ? "active login-nav" : ""
                }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("career")}
                  to="/current_opening"
                >
                  Careers
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${
                  location.pathname === "/contact" ? "active login-nav" : ""
                  // activeLink === "contact" ? "active login-nav" : ""
                }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("contact")}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </Nav.Link>
            </Nav>
            <div>
              <Link to="/login" type="button" className="btn contact-btn">
                Log In
              </Link>
              <Link
                to="/form"
                onClick={() => handleLinkClick("form")}
                type="button"
                className="btn register_animated_btn contact-btn ms-3"
              >
                Register Now
              </Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
