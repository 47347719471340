import React from "react";
import "../Services.css";
import { Helmet } from "react-helmet";
import aboutBg from "../../../component/asset/image/services/s4.webp";
const ItServices = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Staff Services,Employee Benefits,Human Resources Solutions,Workforce Management,Payroll Administration,Staff Training and Development,Performance Evaluation,Employee Engagement,Time and Attendance Tracking,HR Policies"
        />
        <meta
          name="description"
          content="Discover our comprehensive staff services designed to optimize your workforce management. We offer employee benefits, HR solutions, payroll administration, staff training, performance evaluation, and more to enhance employee engagement and productivity."
        />
        <title>Staff Services | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <section className="Services">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={aboutBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
        <div className="it-service-second-sec mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="it-service-left-sec">
                  <h3 className="text-uppercase common-text">Staff Services</h3>
                  <hr className="heading_line" />
                  <p className="it-service-left-para">
                    Staff services refer to the various support functions and
                    administrative tasks that are essential for the smooth
                    functioning of an organization. These services are typically
                    provided by staff members who work behind the scenes to
                    ensure that the day-to-day operations run efficiently and
                    effectively. Staff services play a critical role in
                    supporting the core business activities and helping the
                    organization achieve its goals. Some common staff services
                    include:
                  </p>
                  <h6 className="text-uppercase fw-bold mt-3">
                    Human Resources (HR){" "}
                  </h6>
                  <hr className="heading_line" />
                  <p className="it-service-left-para">
                    HR staff are responsible for managing employee recruitment,
                    onboarding, training and development, payroll, benefits
                    administration, employee relations, and compliance with
                    labor laws and regulations.
                  </p>
                  <h6 className="text-uppercase fw-bold mt-3">
                    Information Technology (IT)
                  </h6>
                  <hr className="heading_line" />
                  <p className="it-service-left-para">
                    IT staff manage the organization's technology
                    infrastructure, including computer systems, networks,
                    software, and data security. They provide technical support
                    to employees and ensure that IT resources are up and running
                    smoothly.
                  </p>
                  <h6 className="text-uppercase fw-bold mt-3">
                    Finance and Accounting
                  </h6>
                  <hr className="heading_line" />
                  <p className="it-service-left-para">
                    Finance and accounting staff handle financial transactions,
                    bookkeeping, budgeting, financial reporting, tax compliance,
                    and financial analysis to support the organization's
                    financial health.
                  </p>
                  <h6 className="text-uppercase fw-bold mt-3">
                    Administrative Support
                  </h6>
                  <hr className="heading_line" />
                  <p className="it-service-left-para">
                    Administrative staff assist with day-to-day office tasks,
                    including managing schedules, handling correspondence,
                    filing documents, and providing general administrative
                    support to various departments
                  </p>
                  <h6 className="text-uppercase fw-bold mt-3">
                    Facilities Management
                  </h6>
                  <hr className="heading_line" />
                  <p className="it-service-left-para">
                    Facilities staff oversee the maintenance and management of
                    the organization's physical facilities, ensuring a safe,
                    clean, and functional work environment.
                  </p>
                  <h6 className="text-uppercase fw-bold mt-3">
                    Legal and Compliance
                  </h6>
                  <hr className="heading_line" />
                  <p className="it-service-left-para">
                    Legal staff handle legal matters, contracts, and regulatory
                    compliance to ensure that the organization operates within
                    the bounds of the law.
                  </p>
                  <h6 className="text-uppercase fw-bold mt-3">
                    Marketing and Communications
                  </h6>
                  <hr className="heading_line" />
                  <p className="it-service-left-para">
                    Marketing and communications staff develop and execute
                    marketing strategies, manage the organization's brand and
                    public image, and handle internal and external
                    communications.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="it-service-right-sec">
                  <h3 className="text-uppercase common-text">WHY CHOOSE US</h3>
                  <hr className="heading_line mb-5" />
                  <div className="it-service-right-first">
                    <h5 className="common-text">Right Vision</h5>
                    <hr className="heading_line" />
                    <p className="it-service-right-para mt-4">
                      Our Vision is inspired with a mission to create Leaders,
                      Winners and Achievers in a Global Competitive World. To be
                      globally recognized as Reputed Professional Manpower
                      Consulting Firm for Corporate, Individuals and
                      Entrepreneurs.
                    </p>
                  </div>
                  <div className="it-service-right-second">
                    <h5 className="common-text">Right Mission</h5>
                    <hr className="heading_line" />
                    <p className="it-service-right-para mt-4">
                      We are committed to support our esteemed client's
                      requirements by delivering on time, building trustworthy
                      relationships and by offering complete recruitment and
                      training solutions..
                    </p>
                  </div>
                  <div className="it-service-right-third">
                    <h5 className="common-text">Right Values</h5>
                    <hr className="heading_line" />
                    <ul className="mt-4">
                      <li> Build Long Term Relationships</li>
                      <li> Empower People</li>
                      <li> Spread Knowledge</li>
                      <li> Build Trust</li>
                      <li> Result Oriented</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItServices;
