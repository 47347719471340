import React, { useEffect, useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
const Login = () => {
  useEffect(() => {
    if (sessionStorage.getItem("info") != null) {
      console.log("User is already logged in");
      navigate("/user-panel");
    }
  }, []);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      email: "",
      password: "",
    };

    // Email validation (required and valid email format)
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      valid = false;
    }

    // Name validation (required field)
    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = formData;
    if (validateForm()) {
      console.log("login data:", formData);
    }

    try {
      const finalData = { email: email, password: password };
      const response = await axios.post(
        API_BASE_URL + "user/login",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        sessionStorage.setItem("info", JSON.stringify(response.data.data));
        toast.success(response.data.message);
        navigate("/user-panel");
      } else {
        toast.error(response.data.error);
        // window.alert(response.data.error);
      }
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Staff Management Software</title>
      </Helmet>
      <section className="login-main">
        <div className="container">
          <div className="row login-sub-sec">
            <div className="col-lg-6 login-first-sec">
              <div className="login-title-sec">
                <h1 className="text-white text-uppercase fw-bold">
                  SVK SUPPLIERS PRIVATE LIMITED
                </h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card login-card">
                <div className="card-body h-custom-2 d-flex justify-content-center align-items-center">
                  <form style={{ width: "23rem" }} onSubmit={handleSubmit}>
                    <h3 className="fw-bold mb-3 text-uppercase text-center text-white pb-3">
                      Log in
                    </h3>
                    <div className="form-outline mb-4">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        id="form2Example18"
                        className="form-control form-control-lg"
                      />
                      <label
                        className="form-label text-white"
                        htmlFor="form2Example18"
                      >
                        Email address
                      </label>
                      {errors.email && (
                        <div className="error text-danger">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-outline mb-4">
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        id="form2Example28"
                        className="form-control form-control-lg"
                      />
                      <label
                        className="form-label text-white"
                        htmlFor="form2Example28"
                      >
                        Password
                      </label>
                      {errors.password && (
                        <div className="error text-danger">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div className="pt-1 mb-4">
                      <button
                        className="btn btn-primary form-control btn-lg btn-block"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                    {/* <p className="small mb-5 text-white pb-lg-2">
                      <a className="text-white" href="#!">
                        Forgot password?
                      </a>
                    </p> */}
                    <p className="text-white">
                      Don't have an account?{" "}
                      <Link to="/form" className="link-info">
                        Register here
                      </Link>
                    </p>
                    <ToastContainer
                      position={"top-center"}
                      autoClose={2000}
                      pauseOnHover={false}
                      closeOnClick={true}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
