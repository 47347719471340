import React from "react";
import "../Career/Why_Right.css";
const Why_Right = () => {
  return (
    <div className="why-right">
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active common-top-bg">
            {/* <img
              src={aboutBg}
              className="d-block w-100"
              alt="..."
            /> */}
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-lg-8">
            <div className="MainSection">
              <h4>Culture Diversity</h4>
              <p className="mt-4">
                Right Move provide opportunities to people from diverse
                backgrounds and geographies to come together in pursuit of a
                common vision.
              </p>
            </div>
            <div className="MainSection mt-4">
              <h4>Globalization</h4>
              <p className="mt-4">
                We focus on global teaming and we constantly improve ourselves
                to provide better career to our employees. We provide
                opportunities to make you grow as an individual and as a team
                leader.
              </p>
            </div>
            <div className="MainSection mt-4">
              <h4>Values</h4>
              <p className="mt-4">
                Our core values – Professionalism, Perfection, Passion and
                Pro-action is inculcated among the employees to encourage and
                support growth.
              </p>
            </div>
            <div className="MainSection mt-4">
              <h4>On The Job Learning</h4>
              <p className="mt-4">
                We train our employees to think constantly, approaching old
                problems in new ways, and recognizing gaps that may not always
                be apparent. This training helps them to deliver the quality
                output.
              </p>
            </div>
            <div className="MainSection mt-4">
              <h4>Open Door Culture</h4>
              <p className="mt-4">
                We are always attentive to our employees and value them.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <img src="" className="d-flex" alt="images" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why_Right;
