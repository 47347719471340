/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import "./Home.css";
import bgOne from "../../component/asset/image/services/image1.webp";
import bgTwo from "../../component/asset/image/services/image2.webp";
import bgThree from "../../component/asset/image/services/image3.webp";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Helmet from "react-helmet";
import { Carousel } from "react-bootstrap";
import AutoSlider from "./AutoSlider";
import { useNavigate } from "react-router-dom";
const Home = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="staff management, employee scheduling, attendance tracking, payroll management, workforce optimization, staff management system, employee management software, HR solutions"
        />
        <meta
          name="description"
          content="Streamline your organization's efficiency with our comprehensive staff management solution. Simplify scheduling, track attendance, manage payroll, and optimize workforce productivity."
        />
        <title>Home | Staff Management Software</title>
      </Helmet>
      <section className="slider-img" style={{ overflowX: "hidden" }}>
        {/* <div className="imgSlider">
        <div className="slider-text h-100 d-flex justify-content-center align-items-center">
          <h1 className="imgText">Staff Management Software</h1>
        </div>
      </div> */}
        {/* carousel   */}
        <div className="slider-container ps-0 pe-0 container-fluid">
          <Carousel interval={2000} pauseOnHover={false}>
            <Carousel.Item>
              <img className="d-block w-100" src={bgOne} alt="Image 1" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={bgTwo} alt="Image 2" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={bgThree} alt="Image 3" />
            </Carousel.Item>
            {/* Add more Carousel.Item as needed */}
          </Carousel>
        </div>
        {/* carousel   */}
        <div className="container-fluid ps-0 pl-0 pe-0">
          <div className="row ps-0 pl-0">
            <div className="main_section pb-5">
              <div className="col-lg-4">
                <div className="box1">
                  <h1 className="text-white fw-bold fs-4 heading">ABOUT US</h1>
                  <hr className="heading_line" style={{ Color: "#ffffff" }} />
                  <p className="fs-15 mt-3 text-white">
                    SVK SUPPLIERS PRIVATE LIMITED is a leading service provider
                    of recruitment and training needs providing exceptional
                    services to our clients and job seekers across the country.
                  </p>
                  <p className="text-white fs-15">
                    Established in the Year 2022 we at Staff Management are a
                    professionally managed placement and training consultancy
                    firm with network across India. We have built enduring
                    relationship with our clients across India. As a service
                    partner, we help them source professionals with intellectual
                    skills to help organizations build their human capital on
                    one hand and on the other we help individuals build their
                    career.
                  </p>
                  <div className="about-call-btn">
                    <a
                      href="Tel:8686553001"
                      className="btn_call text-decoration-none fw-bold mt-3"
                    >
                      Call Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="box2">
                  <h1 className=" fs-4 common-text heading">SERVICES</h1>
                  <hr className="heading_line" />
                  <div className="img1">
                    <div className="image mt-4">
                      <img
                        src={require("./Images/recruitment-icon-6.png")}
                        alt=""
                        height={40}
                      />
                      <h5 className="mt-3 fw-bold">Recruitment</h5>
                      <p>
                        As a Staff Management Specialist, your primary
                        responsibility will be to oversee the entire recruitment
                        process, from sourcing candidates to onboarding new
                        hires.
                      </p>
                    </div>
                    <div className="image2 mt-4">
                      <img
                        src={require("./Images/courses-icon-6.png")}
                        alt=""
                        height={40}
                      />
                      <h5 className="mt-3 fw-bold">Training</h5>
                      <p>
                        Our panel of Professionals having long-standing
                        experience in the Industry and other vital
                        sectors.Specialists offer focused solutions to the
                        need-based challenges of today’s competition.
                      </p>
                    </div>
                  </div>
                  <div className="img2">
                    <div className="image">
                      <img
                        src={require("./Images/Content.png")}
                        alt=""
                        height={40}
                      />
                      <h5 className="mt-3 fw-bold">Job Fair</h5>
                      <p>
                        The SVK SUPPLIERS PRIVATE LIMITED Job Fair is not just
                        about finding a job; it's an opportunity to discover
                        your potential, connect with industry professionals, and
                        take the next step in your career.
                      </p>
                    </div>
                    <div className="image2">
                      <img
                        src={require("./Images/icon4.png")}
                        alt=""
                        height={40}
                      />
                      <h5 className="mt-3 fw-bold">Staffing Services</h5>
                      <p>
                        At SVK SUPPLIERS PRIVATE LIMITED, we understand that the
                        success of any organization lies in its people. As a
                        leading staffing services provider, we are committed to
                        bridging the gap between exceptional talent and
                        businesses in need of top-notch professionals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section card */}
        <div className="container">
          <div className="row">
            <header className="mb-3">
              <center>
                <h4 className="text-center common-text">
                  OUR STAFFING SOLUTIONS
                </h4>
                <hr className="heading_line" style={{ Color: "#ffffff" }} />
              </center>
            </header>
            <div className="col-lg-3">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  className="image_hover"
                  image={require("../../component/asset/image/staff_temporary.webp")}
                  title="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Temporary
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    SVK offers flexible staffing options. Many companies are
                    leveraging the services
                  </Typography>
                </CardContent>
                {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
              </Card>
            </div>
            <div className="col-lg-3">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  className="image_hover"
                  sx={{ height: 140 }}
                  image={require("../../component/asset/image/permanent_staff.jpg")}
                  title="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Permanent
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    SVK , is a full-service human resource consulting firm which
                    has been operating throughout India
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="col-lg-3">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  className="image_hover"
                  image={require("../../component/asset/image/contract_to_hire.jpg")}
                  title="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Contract-to-Hire
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    By utilizing SVK contract-to-hire staffing services, a
                    company can benefit from the traditional
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="col-lg-3">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  className="image_hover"
                  image={require("../../component/asset/image/Direct_placement.jpg")}
                  title="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Direct Placement
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Through our direct placement, SVK SUPPLIERS PRIVATE LIMITED
                    assumes all responsibility .
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <AutoSlider />
      </section>
    </>
  );
};

export default Home;
