import React from "react";
import "./About.css";
import aboutBg from "../../component/asset/image/services/about_bg.webp";
import aboutLeftOne from "../../component/asset/image/about/director-svk.jpeg";
import aboutLeftTwo from "../../component/asset/image/about/adv-svk.jpeg";
import aboutLeftThree from "../../component/asset/image/about/director-2.jpeg";
import Helmet from "react-helmet";
const About = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="staff management, workforce management, HR solutions, staff empowerment, employee management, team values, workforce optimization"
        />
        <meta
          name="description"
          content="At StaffMasters - Your Trusted Staff Management Solution, we are dedicated to streamlining your workforce. Learn about our team, values, and commitment to empowering businesses with top-notch HR solutions."
        />
        <title>About Us | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <section className="about">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active about-bg">
              <img src={aboutBg} className="d-block w-100" alt="aboutBg" />
            </div>
          </div>
        </div>
        <div className="about-second-sec mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="about-left-sec">
                  <h3 className="text-uppercase common-text">About us</h3>
                  <hr className="heading_line" />
                  <p className="about-left-para">
                   

                    SVK SUPPLIERS PRIVATE LIMITED is a leading service provider of recruitment and training <br/>
                     needs providing exceptional services to our clients and job seekers across the country.
                  </p>
                  <p className="about-left-para">
                    Established in the Year 2022 we at SVK are a professionally
                    managed placement and training consultancy firm with network
                    across India. We have built enduring relationship with our
                    clients across India. As a service partner, we help them
                    source professionals with intellectual skills to help
                    organizations build their human capital on one hand and on
                    the other we help individuals build their career. SVK
                    comprises of group of experienced specialists from the
                    Industry. We place great value on Human Resource Development
                    and in placing Right people for the Right job by offering
                    need-based Manpower Recruitment Services with the help of
                    our in-depth knowledge and understanding of your
                    requirements. We combine vision, expertise and experience
                    with entrepreneurial spirit to deliver the most complex,
                    urgent and need based staffing requirements quickly,
                    efficiently and professionally. Placing candidates in the
                    right positions is the key to achieving outstanding business
                    development results.
                  </p>
                  <p className="about-left-para">
                    Our team of experienced and specialized recruitment
                    associates not only does an aggressive search with proper
                    screening of the resumes followed by matching the right
                    candidates to the appropriate job openings but also works
                    closely with clients to determine the exact qualifications
                    and skill set needed in a potential candidate as well as
                    they interview candidates extensively to ensure their
                    experience and needs are appropriate for the job and culture
                    offered by our clients.
                    <br />
                    They are actively involved with the client till the final
                    interview, releasing of offer letter, joining of the
                    candidate and post interview and joining follow-ups. We have
                    been providing our esteemed client with customize prompt
                    high quality service of high ethical standards thereby
                    totally committed to hiring the best professionals to suit
                    every possible vacancy and in turn we have acquired an
                    enviable reputation and trust in India as a leading
                    Employment Agency for Entry, Middle & Senior Management
                    recruitment.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="about-right-sec">
                  <h3 className="text-uppercase common-text">WHY CHOOSE US</h3>
                  <hr className="heading_line mb-5" />
                  <div className="about-right-first">
                    <h5 className="common-text">Right Vision</h5>
                    <hr className="heading_line" />
                    <p className="about-right-para mt-4">
                      Our Vision is inspired with a mission to create Leaders,
                      Winners and Achievers in a Global Competitive World. To be
                      globally recognized as Reputed Professional Manpower
                      Consulting Firm for Corporate, Individuals and
                      Entrepreneurs.
                    </p>
                  </div>
                  <div className="about-right-second">
                    <h5 className="common-text">Right Mission</h5>
                    <hr className="heading_line" />
                    <p className="about-right-para mt-4">
                      We are committed to support our esteemed client's
                      requirements by delivering on time, building trustworthy
                      relationships and by offering complete recruitment and
                      training solutions..
                    </p>
                  </div>
                  <div className="about-right-third">
                    <h5 className="common-text">Right Values</h5>
                    <hr className="heading_line" />
                    <ul className="mt-4">
                      <li> Build Long Term Relationships</li>
                      <li> Empower People</li>
                      <li> Spread Knowledge</li>
                      <li> Build Trust</li>
                      <li> Result Oriented</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <header>
                <h3 className="text-uppercase common-text">About Profiles</h3>
                <hr className="heading_line mb-5" />
              </header>
              <div className="col-lg-4 mb-5">
                <div className="about-img-left">
                  <img
                    src={aboutLeftOne}
                    alt=""
                    className="rounded"
                    height={300}
                  />
                </div>
              </div>
              <div className="col-lg-8 mb-5">
                <div className="profile-right-about-sec">
                  <h5 className="fw-bold common-text text-uppercase">
                    Sayali Avinash Khandekar
                  </h5>
                  <h4 className="fw-bold text-uppercase">director</h4>
                  <p>
                    Director of SVK company she is responsible for overseeing
                    the company strategy, leading teams, managing budgets,
                    acquiring and developing talent, managing stakeholders,
                    overseeing product development and improving processes. It's
                    challenging but rewarding role that requires a combination
                    of technical expertise business acumen and leadership
                    skills.
                  </p>
                </div>
              </div>
              <div className="col-lg-8 mb-5">
                <div className="about-img-left">
                  <h5 className="fw-bold common-text text-uppercase">
                    Bharti Nanaso Deokate
                  </h5>
                  <h4 className="fw-bold text-uppercase">director</h4>
                  <p>
                    Mrs. Bharti is Director for the company, she is responsible
                    for the significant role in shaping the company's vision,
                    strategy, and overall direction. It's challenging
                    responsibilities might include making important decisions,
                    overseeing various departments, ensuring the company's
                    success and growth, and representing the organization to
                    stakeholders, clients, and the public.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-5">
                <div className="about-img-left">
                  <img
                    src={aboutLeftThree}
                    alt=""
                    className="rounded"
                    height={300}
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-5">
                <div className="profile-right-about-sec">
                  <img
                    src={aboutLeftTwo}
                    alt=""
                    className="rounded"
                    height={300}
                  />
                </div>
              </div>
              <div className="col-lg-8 mb-5">
                <div className="profile-right-about-sec">
                  <h5 className="fw-bold common-text text-uppercase">
                    Adv. vijaykumar khandekar
                  </h5>
                  <h4 className="fw-bold text-uppercase">Legal adviser</h4>
                  <p>
                    Mr. Vijaykumar Khandekar is legal advisor for the company,
                    his role are crucial in providing legal guidance and support
                    to ensure the company operates within the confines of the
                    law and mitigates legal risks associated with transactions
                    and operations. It's challenging responsibilities are
                    advising on employment-related matters, including contracts,
                    workplace policies, employee rights, and disputes.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
