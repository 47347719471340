import React from "react";
import { Helmet } from "react-helmet";
import trainingBg from "../../../component/asset/image/services/s2.webp";
const Training = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Staff training,Employee development,Training programs,Professional development,Skill enhancement,Team building,Leadership training,Workshops,Training courses,Performance improvement"
        />
        <meta
          name="description"
          content="Enhance your workforce's capabilities with our comprehensive staff training programs. Our employee development initiatives include workshops, leadership training, and skill enhancement courses designed to boost performance and foster effective team building. Invest in professional development to drive success in your organization."
        />
        <title>Training | SVK SUPPLIERS PRIVATE LIMITED</title>
      </Helmet>
      <section className="training">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active training-bg">
              <img src={trainingBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
        <div className="training-second-sec mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="training-left-sec">
                  <h3 className="text-uppercase common-text">training</h3>
                  <hr className="heading_line" />
                  <div className="training-common-sec">
                    <span className="training-span mt-2">
                      Corporate Training Programmes :
                    </span>
                    <br />
                    <p className="training-left-para">
                      Training in a company refers to the process of providing
                      employees with the knowledge, skills, and competencies
                      required to perform their job effectively and efficiently.
                      Training is a crucial aspect of employee development and
                      contributes to the overall success and growth of the
                      organization. Here are some common types of training:
                    </p>
                    <span className="training-span mt-2">
                      Onboarding Training
                    </span>
                    <p className="training-left-para">
                      This is provided to new employees to help them integrate
                      into the company culture, understand their roles and
                      responsibilities, and get familiar with the organization's
                      policies and procedures.
                    </p>
                    <span className="training-span mt-2">Skills Training</span>
                    <p className="training-left-para">
                      Companies often offer training programs to enhance
                      specific job-related skills, such as technical skills,
                      communication skills, leadership skills, and
                      problem-solving skills.
                    </p>
                    <span className="training-span mt-2">
                      Compliance Training
                    </span>
                    <p className="training-left-para">
                      To ensure that employees are aware of and adhere to legal
                      and regulatory requirements, companies conduct compliance
                      training on topics like workplace safety, data protection,
                      and ethical conduct.
                    </p>
                    <span className="training-span mt-2">
                      Product or Service Training
                    </span>
                    <p className="training-left-para">
                      For companies that offer specific products or services,
                      training is provided to employees to have a comprehensive
                      understanding of what they are offering to customers.
                    </p>
                    <span className="training-span mt-2">
                      Management and Leadership Training
                    </span>
                    <p className="training-left-para">
                      This type of training is designed for managers and leaders
                      within the organization to improve their ability to lead
                      teams, make strategic decisions, and foster a positive
                      work environment.
                    </p>
                    <span className="training-span mt-2">
                      Professional Development
                    </span>
                    <p className="training-left-para">
                      Training programs aimed at career advancement, personal
                      growth, and continuous learning are offered to employees
                      who want to improve their skills and knowledge in various
                      areas.
                    </p>
                    <span className="training-span mt-2">
                      Diversity and Inclusion Training
                    </span>
                    <p className="training-left-para">
                      Companies may conduct training to promote diversity and
                      inclusion in the workplace, fostering a respectful and
                      inclusive environment.
                    </p>
                    <span className="training-span mt-2">
                      Technology and Software Training
                    </span>
                    <p className="training-left-para">
                      With the rapid advancement of technology, companies may
                      provide training on new software or technologies relevant
                      to employees' roles.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="about-right-sec">
                  <h3 className="text-uppercase common-text">WHY CHOOSE US</h3>
                  <hr className="heading_line mb-5" />
                  <div className="about-right-first">
                    <h5 className="common-text">Right Vision</h5>
                    <hr className="heading_line" />
                    <p className="about-right-para mt-4">
                      Our Vision is inspired with a mission to create Leaders,
                      Winners and Achievers in a Global Competitive World. To be
                      globally recognized as Reputed Professional Manpower
                      Consulting Firm for Corporate, Individuals and
                      Entrepreneurs.
                    </p>
                  </div>
                  <div className="about-right-second">
                    <h5 className="common-text">Right Mission</h5>
                    <hr className="heading_line" />
                    <p className="about-right-para mt-4">
                      We are committed to support our esteemed client's
                      requirements by delivering on time, building trustworthy
                      relationships and by offering complete recruitment and
                      training solutions..
                    </p>
                  </div>
                  <div className="about-right-third">
                    <h5 className="common-text">Right Values</h5>
                    <hr className="heading_line" />
                    <ul className="mt-4">
                      <li> Build Long Term Relationships</li>
                      <li> Empower People</li>
                      <li> Spread Knowledge</li>
                      <li> Build Trust</li>
                      <li> Result Oriented</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Training;
